.wmd-panel{width:100%;}
.wmd-input{height:300px;width:100%;box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-ms-box-sizing:border-box;}
.wmd-preview{width:100%;margin-top: 20px;}
.wmd-panel .btn-toolbar{margin-bottom:0;padding:0 0 5px;width:100%;}
.fa-header:before{content:'H';font-family:arial,helvetica,sans-serif;font-weight:bold;}
.wmd-prompt-background{background-color:Black;}
.wmd-prompt-dialog{border:1px solid #999999;background-color:#F5F5F5;}
.wmd-prompt-dialog>div{font-size:0.8em;font-family:arial,helvetica,sans-serif;}
.wmd-prompt-dialog>form>input[type="text"]{border:1px solid #999999;color:black;}
.wmd-prompt-dialog>form>input[type="button"]{border:1px solid #888888;font-family:trebuchet MS,helvetica,sans-serif;font-size:0.8em;font-weight:bold;}
.wmd-button-group1 {margin-left: 5px !important;}
.wmd-button-bar { margin-bottom: 5px;}