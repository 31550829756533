/* === Mobile devices - Portrait (320px wide) === */

@media only screen and (min-width: 20em) {


}
/* === Mobile devices - Landscape (480px wide) === */

@media only screen and (min-width: 30em) {

}
/* === Tablet devices - Portrait (768px wide) === */

@media only screen and (min-width: 48em) {


}
/* === Tablet devices - Landscape (900px wide) === */

@media only screen and (max-width: 81.25em) {

    #page-sidebar {
        width: 100px;
    }
    #page-content {
        margin-left: 100px;
        -webkit-transition: margin 0.5s ease;
        -o-transition: margin 0.5s ease;
        transition: margin 0.5s ease;
    }
    #header-logo .logo-content-big {
        visibility: hidden;
        display: none;
    }
    #close-sidebar {
        display: none;
    }
    #page-sidebar li a.sf-with-ul:after,
    #sidebar-menu li.divider,
    #sidebar-menu li.header {
        display: none;
    }
    #page-sidebar #sidebar-menu > li > a {
        line-height: 46px;
        height: 46px;
    }

    #page-sidebar li a .glyph-icon {
        margin: 0 auto;
        float: none;
        height: 46px;
        line-height: 46px;
        width: 46px;
        font-size: 20px;
    }

    #page-header {
        padding-left: 100px;
    }

    #page-sidebar #sidebar-menu > li .bs-label {
        display: none;
    }

    #page-sidebar.sidebar-hover {
        width: 300px;
    }

    #page-sidebar #sidebar-menu > li > a span {
        visibility: hidden;
    }

    #page-sidebar.sidebar-hover #sidebar-menu > li > a span {
        visibility: visible;
        transition-delay:0.2s;
    }

    #page-sidebar.sidebar-hover #sidebar-menu > li > a {
        line-height: 32px;
        height: 32px;
    }

    #page-sidebar #sidebar-menu li .sidebar-submenu {
        display: none !important;
    }

    #page-sidebar.sidebar-hover #sidebar-menu li.sfHover .sidebar-submenu {
        display: block !important;
    }

    #page-sidebar.sidebar-hover li a.sf-with-ul:after,
    #page-sidebar.sidebar-hover #sidebar-menu li.divider,
    #page-sidebar.sidebar-hover #sidebar-menu li.header {
        display: block;
    }

    #page-sidebar.sidebar-hover li a .glyph-icon {
        font-size: 16px;
        line-height: 31px;
        display: block;
        float: left;
        width: 32px;
        height: 32px;
        margin-right: 10px;
        margin-left: 0;
    }

    #header-logo .logo-content-small {
        display: block;
    }
}

@media only screen and (min-width: 56.25em) {



    /* Closed sidebar */

    #page-sidebar {
        -webkit-transition: width 0.5s ease;
        -o-transition: width 0.5s ease;
        transition: width 0.5s ease;
        visibility: visible;
    }
    .closed-sidebar #page-sidebar {
        width: 100px;
    }
    .closed-sidebar #page-content {
        margin-left: 100px;
        -webkit-transition: margin 0.5s ease;
        -o-transition: margin 0.5s ease;
        transition: margin 0.5s ease;
    }
    .closed-sidebar #header-logo .logo-content-big {
        visibility: hidden;
    }
    .closed-sidebar #close-sidebar {
        right: 50%;
        margin-right: -14px;
    }
    .closed-sidebar #page-sidebar li a.sf-with-ul:after,
    .closed-sidebar #sidebar-menu li.divider,
    .closed-sidebar #sidebar-menu li.header {
        display: none;
    }
    .closed-sidebar #page-sidebar #sidebar-menu > li > a {
        line-height: 46px;
        height: 46px;
    }

    .closed-sidebar #page-sidebar li a .glyph-icon {
        margin: 0 auto;
        float: none;
        height: 46px;
        line-height: 46px;
        width: 46px;
        font-size: 20px;
    }

    .closed-sidebar #page-header {
        padding-left: 100px;
    }

    .closed-sidebar #page-sidebar #sidebar-menu > li .bs-label {
        display: none;
    }

    .closed-sidebar #page-sidebar.sidebar-hover {
        width: 300px;
    }

    .closed-sidebar #page-sidebar #sidebar-menu > li > a span {
        visibility: hidden;
    }

    .closed-sidebar #page-sidebar.sidebar-hover #sidebar-menu > li > a span {
        visibility: visible;
        transition-delay:0.2s;
    }

    .closed-sidebar #page-sidebar.sidebar-hover #sidebar-menu > li > a {
        line-height: 32px;
        height: 32px;
    }

    .closed-sidebar #page-sidebar #sidebar-menu li .sidebar-submenu {
        display: none !important;
    }

    .closed-sidebar #page-sidebar.sidebar-hover #sidebar-menu li.sfHover .sidebar-submenu {
        display: block !important;
    }

    .closed-sidebar #page-sidebar.sidebar-hover li a.sf-with-ul:after,
    .closed-sidebar #page-sidebar.sidebar-hover #sidebar-menu li.divider,
    .closed-sidebar #page-sidebar.sidebar-hover #sidebar-menu li.header {
        display: block;
    }

    .closed-sidebar #page-sidebar.sidebar-hover li a .glyph-icon {
        font-size: 16px;
        line-height: 31px;
        display: block;
        float: left;
        width: 32px;
        height: 32px;
        margin-right: 10px;
        margin-left: 0;
    }


    #theme-options,
    #header-logo {
        display: block;
    }
    #page-sidebar.collapse {
        display: block;
    }
}
/* === Netbook devices (1100px wide) === */

@media only screen and (min-width: 68.75em) {

}
/* === Desktop devices (1300px wide) === */

@media only screen and (min-width: 81.25em) {


}
/* === Desktop devices (1600px wide) === */

@media only screen and (min-width: 81.25em) {

}

/* Custom */
@media only screen and (max-width: 760px) {

    #dashnav-btn,
    #notifications-btn,
    #header-nav-right #fullscreen-btn,
    #cloud-btn {
        display: none;
    }

    .dropdown-menu {
        position: fixed;
        left: 0!important;
        width: 100%;
        border-radius: 0;
    }

    #mobile-navigation {
        display: block;
        position: absolute;
        left: 10px;
        top: 13px;
        z-index: 555;
    }

    #page-content-wrapper {
        padding-top: 0;
    }

    #page-header {
        padding-left: 58px;
    }

    #page-content {
        margin-left: 0;
    }

    #page-sidebar {
        width: 100%;
        position: relative;
        display: none;
    }


    #page-sidebar #sidebar-menu > li .bs-label {
        display: none;
    }

    #page-sidebar #sidebar-menu > li > a span {
        visibility: hidden;
    }

    #page-sidebar #sidebar-menu > li > a span {
        visibility: visible;
        transition-delay:0.2s;
    }

    #page-sidebar #sidebar-menu > li > a {
        line-height: 32px;
        height: 32px;
    }

    #page-sidebar #sidebar-menu li .sidebar-submenu {
        display: none !important;
    }

    #page-sidebar #sidebar-menu li.sfHover .sidebar-submenu {
        display: block !important;
    }

    #page-sidebar li a.sf-with-ul:after,
    #page-sidebar #sidebar-menu li.divider,
    #page-sidebar #sidebar-menu li.header {
        display: block;
    }

    #page-sidebar li a .glyph-icon {
        font-size: 16px;
        line-height: 31px;
        display: block;
        float: left;
        width: 32px;
        height: 32px;
        margin-right: 10px;
        margin-left: 0;
    }




}

@media only screen and (max-width: 630px) {

    #page-header .user-account-btn > a.user-profile span {
        display: none;
    }
    #page-header .user-account-btn .dropdown-menu:after,
    #page-header .user-account-btn .dropdown-menu:before {
        left: 56px;
    }

    #page-sidebar {
        width: 100%;
        position: relative;
    }

    #page-sidebar .scroll-sidebar {
        height: auto !important;
    }

}
@media only screen and (max-width: 545px) {

    #header-nav-right #chatbox-btn {
        display: none;
    }

}
@media only screen and (max-width: 520px) {

    #dashnav-btn,
    #page-header .user-account-btn > a.user-profile span {
        display: none;
    }
    .popover {
        width: 100%;
        border-radius: 0;
    }
    .box-md {
        width: auto;
    }

}
@media only screen and (max-width: 991px) {

    .box-sm {
        width: auto;
    }

    #page-content {
        padding: 10px;
    }

    #page-title {
        padding: 20px 0;
        margin: 0;
        text-align: center;
        background: none;
    }

    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
        margin-bottom: 20px;
    }

    .content-box {
        margin-top: 0 !important;
    }

    .row.mrg20B {
        margin-bottom: 0 !important;
    }

    .timeline-box .popover {
        width: auto;
    }

    .header-buttons-separator,
    #progress-btn,
    #page-title p,
    .dashboard-box .list-grade,
    .tooltip .tooltip-arrow,
    .todo-box .btn,
    .todo-box .bs-label {
        display: none;
    }

}
@media only screen and (max-width: 410px) {

    #header-nav-right #logout-btn,
    #notifications-btn {
        display: none;
    }
    #header-nav-left {
        float: right;
    }
    #header-nav-right {
        margin-right: 0;
        margin-left: 0;
        padding-left: 0;
        float: left;
    }
    .dropdown-menu {
        position: fixed;
        left: 0 !important;
        width: 100%;
        border-radius: 0;
    }
    #page-header .user-account-btn .dropdown-menu {
        top: 65px;
    }
    #page-header .user-account-btn .dropdown-menu:after,
    #page-header .user-account-btn .dropdown-menu:before {
        left: auto;
        right: 24px;
    }

}