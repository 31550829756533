/* Timeline */

.timeline-box {
    position: relative;
}
.timeline-box:before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    margin-left: 0;
    content: '';
    border-left: 1px solid transparent;
}
.timeline-box .tl-row {
    position: relative;
    padding: 10px 0;
}
.timeline-box .tl-row .tl-item {
    width: 50%;
}
.timeline-box .popover {
    min-width: 50px;
}
.timeline-box .tl-row .tl-item .popover {
    position: relative;
    display: block;
    margin: 0;
    box-shadow: 0 0 0 transparent;
}
.timeline-box .tl-row .tl-item .popover.left {
    float: right;
    margin-right: 35px;
}
.timeline-box .tl-row .tl-item.float-right .popover.right {
    margin-left: 35px;
}
.timeline-box .tl-row .tl-item .tl-panel {
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    margin-top: -8px;
    margin-left: 23px;
    text-align: left;
}
.timeline-box .tl-row .tl-item.float-right .tl-panel {
    right: 50%;
    left: auto;
    margin-right: 23px;
    margin-left: 0;
    text-align: right;
}
.timeline-box .tl-row .tl-item .tl-title {
    font-size: 15px;
    font-weight: bold;
    margin: 0 0 10px;
}
.timeline-box .tl-row .tl-item .tl-label {
    display: inline-block;
    margin-bottom: 10px;
    padding: 5px 8px;
}
.timeline-box .tl-row .tl-item .tl-content {
    font-size: 13px;
    margin: 0 0 10px;
    opacity: .80;
    -moz-opacity: .80;
    filter: alpha(opacity: 80);
}
.timeline-box .tl-row .tl-item .tl-time {
    font-style: italic;
    opacity: .40;
    -moz-opacity: .40;
    filter: alpha(opacity: 40);
}
.timeline-box .tl-row .tl-item .tl-icon,
.timeline-box .tl-row .tl-item .tl-bullet {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 17px;
    height: 17px;
    margin-top: -9px;
    margin-left: -8px;
    border-radius: 100px;
}
.timeline-box .tl-row .tl-item .tl-bullet:before {
    display: block;
    width: 9px;
    height: 9px;
    margin: 4px 0 0 4px;
    content: '';
    border-radius: 100px;
    background: #fff;
}
.timeline-box .tl-row .tl-item .tl-icon {
    font-size: 18px;
    line-height: 34px;
    width: 34px;
    height: 34px;
    margin-top: -18px;
    margin-left: -17px;
    text-align: center;
}
/* Timeline Horizontal */

.timeline-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
}
.timeline-horizontal {
    height: 400px;
    padding: 10px 0;
}
.timeline-horizontal .tl-row {
    float: left;
    width: 400px;
    height: 173px;
}
.timeline-horizontal.timeline-box:before {
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    border: 0;
}
.timeline-horizontal.timeline-box .tl-row .tl-item {
    width: auto;
}
.timeline-horizontal.timeline-box .tl-row .tl-item .tl-panel {
    top: auto;
    bottom: -60px;
    left: 0;
    width: 100%;
    margin: 0;
    text-align: center;
}
.timeline-horizontal.timeline-box .tl-row .tl-item .tl-bullet {
    margin-left: -9px;
}
.timeline-horizontal.timeline-box .tl-row .tl-item .tl-icon,
.timeline-horizontal.timeline-box .tl-row .tl-item .tl-bullet {
    top: auto;
    bottom: -27px;
}
.timeline-horizontal.timeline-box .tl-row .tl-item .popover {
    position: absolute;
    top: auto;
    bottom: 10px;
    width: 100%;
}
.timeline-horizontal.timeline-box .tl-row .tl-item.float-right .tl-panel {
    bottom: 0;
}
.timeline-horizontal.timeline-box .tl-row .tl-item.float-right .popover {
    top: 220px;
    bottom: auto;
}

/* Timeline columns */

.timeline-box-right.timeline-box .tl-row .tl-item,
.timeline-box-left.timeline-box .tl-row .tl-item {
    width: auto;
    float: none !important;
}

.timeline-box-right.timeline-box .tl-row .tl-item .popover,
.timeline-box-left.timeline-box .tl-row .tl-item .popover {
    float: none;
}

.timeline-box-right.timeline-box:before {
    left: auto;
    right: 17px;
}

.timeline-box-right.timeline-box .tl-row {
    padding-right: 20px;
}

.timeline-box-right.timeline-box .tl-row .tl-item .tl-icon,
.timeline-box-right.timeline-box .tl-row .tl-item .tl-bullet {
    left: auto;
    right: 17px;
    margin-left: 0;
    margin-right: -17px;
}

.timeline-box-left.timeline-box:before {
    right: auto;
    left: 17px;
}

.timeline-box-left.timeline-box .tl-row {
    padding-left: 20px;
}

.timeline-box-left.timeline-box .tl-row .tl-item .tl-icon,
.timeline-box-left.timeline-box .tl-row .tl-item .tl-bullet {
    right: auto;
    left: 17px;
    margin-right: 0;
    margin-left: -17px;
}