.close {
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    float: right;
    opacity: .2;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
    cursor: pointer;
    text-decoration: none;
    opacity: .5;
    color: #000;
    filter: alpha(opacity=50);
}
button.close {
    padding: 0;
    cursor: pointer;
    border: 0;
    background: transparent;
    -webkit-appearance: none;
}
.modal-open {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal {
    position: fixed;
    z-index: 1050;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    overflow: auto;
    overflow-y: scroll;
    outline: 0;
    -webkit-overflow-scrolling: touch;
}
.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    -moz-transition: -moz-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    transform: translate(0, -25%);
}
.modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 10px;
    border-radius: 6px;
}
.modal-content {
    position: relative;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: none;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}
.modal-backdrop {
    position: fixed;
    z-index: 1040;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
}
.modal-backdrop.fade {
    opacity: 0;
    filter: alpha(opacity=0);
}
.modal-backdrop.in {
    opacity: .5;
    filter: alpha(opacity=50);
}
.modal-header {
    min-height: 16.42857143px;
    padding: 15px;
    border-bottom: 1px solid #dfe8f1;
}
.modal-header .close {
    margin-top: 2px;
}
.modal-dialog {
    border: 0;
    box-shadow: 0 15px 45px rgba(0, 0, 0, .3) !important;
}
.modal.display-block .modal-dialog {
    box-shadow: 0 6px 25px rgba(0, 0, 0, .1) !important;
    -webkit-transform: translate(0, -20px);
    -ms-transform: translate(0, -20px);
    transform: translate(0, -20px);
}
.modal-title {
    line-height: 1.42857143;
    margin: 0;
}
.modal-body {
    position: relative;
    padding: 15px;
}
.modal-footer {
    padding: 15px;
    text-align: right;
    border-top: 1px solid #dfe8f1;
}
.modal-footer .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px;
}
.modal-footer .btn-group .btn + .btn {
    margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
    margin-left: 0;
}
@media (min-width: 768px) {
    .modal-dialog {
        width: 600px;
        margin: 30px auto;
    }
    .modal-content {
        -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
        box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    }
    .modal-sm {
        width: 300px;
    }
}
@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
    }
}
.display-block.modal {
    position: static;
    z-index: 5;
    display: block;
    overflow: visible !important;
    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity: 100);
}
.display-block.modal .modal-dialog {
    margin-bottom: 0;
}
.modal-open #page-content-wrapper {
    z-index: inherit;
}
