/* Ion.RangeSlider
// css version 1.9.2
// © 2013-2014 Denis Ineshin | IonDen.com
// ===================================================================================================================*/
/* =====================================================================================================================
// RangeSlider */

.irs {
    position: relative;
    display: block;
}
.irs-line {
    position: relative;
    display: block;
    overflow: hidden;
}
.irs-line-left,
.irs-line-mid,
.irs-line-right {
    position: absolute;
    display: block;
    top: 0;
}
.irs-line-left {
    left: 0;
    width: 10%;
}
.irs-line-mid {
    left: 9%;
    width: 82%;
}
.irs-line-right {
    right: 0;
    width: 10%;
}
.irs-diapason {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
}
.irs-slider {
    position: absolute;
    display: block;
    cursor: default;
    z-index: 1;
}
.irs-slider.single {
    left: 10px;
}
.irs-slider.single:before {
    position: absolute;
    display: block;
    content: "";
    top: -30%;
    left: -30%;
    width: 160%;
    height: 160%;
    background: rgba(0, 0, 0, 0.0);
}
.irs-slider.from {
    left: 100px;
}
.irs-slider.from:before {
    position: absolute;
    display: block;
    content: "";
    top: -30%;
    left: -30%;
    width: 130%;
    height: 160%;
    background: rgba(0, 0, 0, 0.0);
}
.irs-slider.to {
    left: 300px;
}
.irs-slider.to:before {
    position: absolute;
    display: block;
    content: "";
    top: -30%;
    left: 0;
    width: 130%;
    height: 160%;
    background: rgba(0, 0, 0, 0.0);
}
.irs-slider.last {
    z-index: 2;
}
.irs-min {
    position: absolute;
    display: block;
    left: 0;
    cursor: default;
}
.irs-max {
    position: absolute;
    display: block;
    right: 0;
    cursor: default;
}
.irs-from,
.irs-to,
.irs-single {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    cursor: default;
    white-space: nowrap;
}
.irs-grid {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
}
.irs-with-grid .irs-grid {
    display: block;
}
.irs-grid-pol {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 8px;
    background: #000;
}
.irs-grid-pol.small {
    height: 4px;
}
.irs-grid-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100px;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    color: #000;
}
.irs-disable-mask {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0.0);
    z-index: 2;
}
.irs-disabled {
    opacity: 0.4;
}
/* Ion.RangeSlider, Flat UI Skin
// css version 1.9.2
// © 2013-2014 Denis Ineshin | IonDen.com
// ===================================================================================================================*/
/* =====================================================================================================================
// Skin details */

.irs-slider {
    background: #111;
    border-radius: 100px;
}
.irs {
    height: 40px;
}
.irs-with-grid {
    height: 60px;
}
.irs-line {
    height: 12px;
    top: 25px;
}
.irs-line-left {
    height: 12px;
}
.irs-line-mid {
    height: 12px;
}
.irs-line-right {
    height: 12px;
}
.irs-diapason {
    height: 12px;
    top: 25px;
}
.irs-slider {
    width: 5px;
    height: 18px;
    top: 22px;
}
#irs-active-slider,
.irs-slider:hover {} .irs-min,
.irs-max {
    color: #999;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    top: 0;
    padding: 1px 3px;
    background: #e1e4e9;
    border-radius: 4px;
}
.irs-from,
.irs-to,
.irs-single {
    color: #fff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
}
.irs-from:after,
.irs-to:after,
.irs-single:after {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.7);
}
.irs-grid-pol {
    background: #e1e4e9;
}
.irs-grid-text {
    color: #999;
}
.irs-disabled {}
