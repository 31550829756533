.note-editor {
    border: 1px solid #bfc8d1;
}
.note-editor .note-dropzone {
    position: absolute;
    z-index: 1;
    display: none;
    opacity: .95;
    color: #87cefa;
    border: 2px dashed #87cefa;
    background-color: white;
    pointer-event: none;
}
.note-editor .note-dropzone .note-dropzone-message {
    font-size: 28px;
    font-weight: bold;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.note-editor .note-dropzone.hover {
    color: #098ddf;
    border: 2px dashed #098ddf;
}
.note-editor.dragover .note-dropzone {
    display: table;
}
.note-editor .note-toolbar {
    border-bottom: 1px solid #bfc8d1;
    background-color: #FEFEFF;
}
.note-editor.fullscreen {
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;
    width: 100%;
}
.note-editor.fullscreen .note-editable {
    background-color: white;
}
.note-editor.fullscreen .note-resizebar {
    display: none;
}
.note-editor.codeview .note-editable {
    display: none;
}
.note-editor.codeview .note-codable {
    display: block;
}
.note-editor .note-statusbar {
    background-color: #FEFEFF;
}
.note-editor .note-statusbar .note-resizebar {
    width: 100%;
    height: 8px;
    cursor: s-resize;
    border-top: 1px solid #bfc8d1;
}
.note-editor .note-statusbar .note-resizebar .note-icon-bar {
    width: 20px;
    margin: 1px auto;
    border-top: 1px solid #bfc8d1;
}
.note-editor .note-editable {
    overflow: auto;
    padding: 10px;
    outline: 0;
}
.note-editor .note-editable[contenteditable='false'] {
    background-color: #dfe8f1;
}
.note-editor .note-codable {
    font-family: Menlo, Monaco, monospace, sans-serif;
    font-size: 14px;
    display: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0;
    padding: 10px;
    resize: none;
    color: #ccc;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: #222;
    box-shadow: none;
    -ms-box-sizing: border-box;
}
.note-air-editor {
    outline: 0;
}
.note-popover .popover {
    max-width: none;
}
.note-popover .popover .popover-content a {
    display: inline-block;
    overflow: hidden;
    max-width: 200px;
    vertical-align: middle;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.note-popover .popover .arrow {
    left: 20px;
}
.note-popover .popover .popover-content,
.note-toolbar {
    margin: 0;
    padding: 0 0 5px 5px;
}
.note-popover .popover .popover-content > .btn-group,
.note-toolbar > .btn-group {
    margin-top: 5px;
    margin-right: 5px;
    margin-left: 0;
}
.note-popover .popover .popover-content .note-table .dropdown-menu,
.note-toolbar .note-table .dropdown-menu {
    min-width: 0;
    padding: 5px;
}
.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker,
.note-toolbar .note-table .dropdown-menu .note-dimension-picker {
    font-size: 18px;
}
.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-mousecatcher,
.note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-mousecatcher {
    position: absolute!important;
    z-index: 3;
    width: 10em;
    height: 10em;
    cursor: pointer;
}
.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-unhighlighted,
.note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-unhighlighted {
    position: relative!important;
    z-index: 1;
    width: 5em;
    height: 5em;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}
.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-highlighted,
.note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-highlighted {
    position: absolute!important;
    z-index: 2;
    width: 1em;
    height: 1em;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}
.note-popover .popover .popover-content .note-style h1,
.note-toolbar .note-style h1,
.note-popover .popover .popover-content .note-style h2,
.note-toolbar .note-style h2,
.note-popover .popover .popover-content .note-style h3,
.note-toolbar .note-style h3,
.note-popover .popover .popover-content .note-style h4,
.note-toolbar .note-style h4,
.note-popover .popover .popover-content .note-style h5,
.note-toolbar .note-style h5,
.note-popover .popover .popover-content .note-style h6,
.note-toolbar .note-style h6,
.note-popover .popover .popover-content .note-style blockquote,
.note-toolbar .note-style blockquote {
    margin: 0;
}
.note-popover .popover .popover-content .note-color .dropdown-toggle,
.note-toolbar .note-color .dropdown-toggle {
    width: 20px;
    padding-left: 5px;
}
.note-popover .popover .popover-content .note-color .dropdown-menu,
.note-toolbar .note-color .dropdown-menu {
    min-width: 290px;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group,
.note-toolbar .note-color .dropdown-menu .btn-group {
    margin: 0;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group:first-child,
.note-toolbar .note-color .dropdown-menu .btn-group:first-child {
    margin: 0 5px;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title,
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
    font-size: 12px;
    margin: 2px 7px;
    text-align: center;
    border-bottom: 1px solid #eee;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
    font-size: 12px;
    margin: 5px;
    padding: 0 3px;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset:hover,
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset:hover {
    background: #eee;
}
.note-popover .popover .popover-content .note-para .dropdown-menu,
.note-toolbar .note-para .dropdown-menu {
    min-width: 216px;
    padding: 5px;
}
.note-popover .popover .popover-content .note-para .dropdown-menu > div:first-child,
.note-toolbar .note-para .dropdown-menu > div:first-child {
    margin-right: 5px;
}
.note-popover .popover .popover-content .dropdown-menu,
.note-toolbar .dropdown-menu {
    min-width: 90px;
}
.note-popover .popover .popover-content .dropdown-menu.right,
.note-toolbar .dropdown-menu.right {
    right: 0;
    left: auto;
}
.note-popover .popover .popover-content .dropdown-menu.right::before,
.note-toolbar .dropdown-menu.right::before {
    right: 9px;
    left: auto!important;
}
.note-popover .popover .popover-content .dropdown-menu.right::after,
.note-toolbar .dropdown-menu.right::after {
    right: 10px;
    left: auto!important;
}
.note-popover .popover .popover-content .dropdown-menu li a i,
.note-toolbar .dropdown-menu li a i {
    visibility: hidden;
    color: deepskyblue;
}
.note-popover .popover .popover-content .dropdown-menu li a.checked i,
.note-toolbar .dropdown-menu li a.checked i {
    visibility: visible;
}
.note-popover .popover .popover-content .note-fontsize-10,
.note-toolbar .note-fontsize-10 {
    font-size: 10px;
}
.note-popover .popover .popover-content .note-color-palette,
.note-toolbar .note-color-palette {
    line-height: 1;
}
.note-popover .popover .popover-content .note-color-palette div .note-color-btn,
.note-toolbar .note-color-palette div .note-color-btn {
    width: 17px;
    height: 17px;
    margin: 0;
    padding: 0;
    border: 1px solid #fff;
}
.note-popover .popover .popover-content .note-color-palette div .note-color-btn:hover,
.note-toolbar .note-color-palette div .note-color-btn:hover {
    border: 1px solid #000;
}
.note-dialog > div {
    display: none;
}
.note-dialog .note-image-dialog .note-dropzone {
    font-size: 30px;
    line-height: 4;
    min-height: 100px;
    margin-bottom: 10px;
    text-align: center;
    color: lightgray;
    border: 4px dashed lightgray;
}
.note-dialog .note-help-dialog {
    font-size: 12px;
    opacity: .9;
    color: #ccc;
    border: 0;
    background: transparent;
    background-color: #222!important;
    -webkit-opacity: .9;
    -khtml-opacity: .9;
    -moz-opacity: .9;
    -ms-filter: alpha(opacity=90);
    filter: alpha(opacity=90);
}
.note-dialog .note-help-dialog .modal-content {
    border: 1px solid white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.note-dialog .note-help-dialog a {
    font-size: 12px;
    color: white;
}
.note-dialog .note-help-dialog .title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
    color: white;
    border-bottom: white 1px solid;
}
.note-dialog .note-help-dialog .modal-close {
    font-size: 14px;
    cursor: pointer;
    color: #dd0;
}
.note-dialog .note-help-dialog .note-shortcut-layout {
    width: 100%;
}
.note-dialog .note-help-dialog .note-shortcut-layout td {
    vertical-align: top;
}
.note-dialog .note-help-dialog .note-shortcut {
    margin-top: 8px;
}
.note-dialog .note-help-dialog .note-shortcut th {
    font-size: 13px;
    text-align: left;
    color: #dd0;
}
.note-dialog .note-help-dialog .note-shortcut td:first-child {
    font-family: 'Courier New';
    min-width: 110px;
    padding-right: 10px;
    text-align: right;
    color: #dd0;
}
.note-handle .note-control-selection {
    position: absolute;
    display: none;
    border: 1px solid black;
}
.note-handle .note-control-selection > div {
    position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
    width: 100%;
    height: 100%;
    opacity: .3;
    background-color: black;
    -webkit-opacity: .3;
    -khtml-opacity: .3;
    -moz-opacity: .3;
    -ms-filter: alpha(opacity=30);
    filter: alpha(opacity=30);
}
.note-handle .note-control-selection .note-control-handle {
    width: 7px;
    height: 7px;
    border: 1px solid black;
}
.note-handle .note-control-selection .note-control-holder {
    width: 7px;
    height: 7px;
    border: 1px solid black;
}
.note-handle .note-control-selection .note-control-sizing {
    width: 7px;
    height: 7px;
    border: 1px solid black;
    background-color: white;
}
.note-handle .note-control-selection .note-control-nw {
    top: -5px;
    left: -5px;
    border-right: 0;
    border-bottom: 0;
}
.note-handle .note-control-selection .note-control-ne {
    top: -5px;
    right: -5px;
    border-bottom: 0;
    border-left: none;
}
.note-handle .note-control-selection .note-control-sw {
    bottom: -5px;
    left: -5px;
    border-top: 0;
    border-right: 0;
}
.note-handle .note-control-selection .note-control-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize;
}
.note-handle .note-control-selection .note-control-selection-info {
    font-size: 12px;
    right: 0;
    bottom: 0;
    margin: 5px;
    padding: 5px;
    opacity: .7;
    color: white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-color: black;
    -webkit-opacity: .7;
    -khtml-opacity: .7;
    -moz-opacity: .7;
    -ms-filter: alpha(opacity=70);
    filter: alpha(opacity=70);
}
