/* Pie gauge */

.easyPieChart {
    position: relative;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.easyPieChart canvas {
    position: absolute;
    top: 0;
    left: 0;
}
.chart-home,
.chart,
.chart-alt,
.chart-alt-1,
.chart-alt-2,
.chart-alt-3 {
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
}
/* Flot charts */

.chart-wrapper {
    width: 100%;
    height: 350px;
}
.chart-container {
    width: 100%;
    height: 100%;
    font-size: 14px;
    line-height: 1.2em;
}