/*
 * Copyright 2013 Dan Grossman ( http://www.dangrossman.info )
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 */

.daterangepicker.dropdown-menu {
    z-index: 3000;
    max-width: none;
}
.daterangepicker.opensleft .ranges,
.daterangepicker.opensleft .calendar {
    float: left;
    margin: 4px;
}
.daterangepicker.opensright .ranges,
.daterangepicker.opensright .calendar {
    float: right;
    margin: 4px;
}
.daterangepicker .ranges {
    width: 188px;
    margin: 0 0 0 10px;
    text-align: left;
}
.daterangepicker .ranges .range_inputs > div {
    float: left;
}
.daterangepicker .ranges .range_inputs > div:nth-child(2) {
    padding-left: 11px;
}
.daterangepicker .calendar {
    display: none;
    max-width: 270px;
}
.show-calendar.daterangepicker .calendar {
    display: block;
}
.daterangepicker .applyBtn {
    float: right;
    width: 110px;
}
.daterangepicker .cancelBtn {
    float: left;
}
.daterangepicker .calendar th,
.daterangepicker .calendar td {
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
}
.daterangepicker .ranges label {
    font-size: 11px;
    font-weight: bold;
    line-height: 20px;
    display: block;
    width: 74px;
    height: 20px;
    margin-bottom: 2px;
    text-transform: uppercase;
    color: #333;
}
.daterangepicker .ranges input {
    font-size: 11px;
}
.daterangepicker .ranges .input-mini {
    font-size: 11px;
    line-height: 30px;
    display: block;
    width: 88px;
    height: 30px;
    margin: 0 0 10px 0;
    padding: 0 6px;
    vertical-align: middle;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #eee;
}
.daterangepicker .ranges ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.daterangepicker .ranges li {
    margin-bottom: 3px;
    padding: 3px 12px;
    cursor: pointer;
}
.daterangepicker .calendar-date {
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    background: #fff;
}
.daterangepicker .calendar-time {
    line-height: 30px;
    margin: 8px auto 0 auto;
    text-align: center;
}
.daterangepicker {
    position: absolute;
    top: 100px;
    left: 20px;
    margin-top: 1px;
    padding: 5px !important;
    background: #fff;
}
.daterangepicker.opensleft:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block;
    content: '';
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, .2);
    border-left: 7px solid transparent;
}
.daterangepicker.opensleft:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block;
    content: '';
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
}
.daterangepicker.opensright:before {
    position: absolute;
    top: -7px;
    left: 9px;
    display: inline-block;
    content: '';
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-bottom-color: rgba(0, 0, 0, .2);
    border-left: 7px solid transparent;
}
.daterangepicker.opensright:after {
    position: absolute;
    top: -6px;
    left: 10px;
    display: inline-block;
    content: '';
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
}
.daterangepicker table {
    width: 100%;
    margin: 0;
}
.daterangepicker td,
.daterangepicker th {
    width: 20px;
    height: 20px;
    cursor: pointer;
    text-align: center;
    white-space: nowrap;
}
.daterangepicker td.off {
    color: #999;
}
.daterangepicker td.in-range {
    border-radius: 0;
}
.daterangepicker td.in-range:hover {
    color: #000;
}
.daterangepicker td.week,
.daterangepicker th.week {
    font-size: 80%;
    color: #ccc;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
    font-size: 12px;
    height: auto;
    margin: 0;
    padding: 1px;
    cursor: default;
}
.daterangepicker select.monthselect {
    width: 56%;
    margin-right: 2%;
}
.daterangepicker select.yearselect {
    width: 40%;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.ampmselect {
    width: 50px;
    margin-bottom: 0;
}
