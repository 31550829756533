@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/delight/fontawesome-webfont.eot?v=4.0.3');
    src: url('../fonts/delight/fontawesome-webfont.eot?#iefix&v=4.0.3') format('embedded-opentype'),
        url('../fonts/delight/fontawesome-webfont.woff?v=4.0.3') format('woff'),
        url('../fonts/delight/fontawesome-webfont.ttf?v=4.0.3') format('truetype'),
        url('../fonts/delight/fontawesome-webfont.svg?v=4.0.3#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon-glass:before {
    content: "\f000";
}
.icon-music:before {
    content: "\f001";
}
.icon-search:before {
    content: "\f002";
}
.icon-envelope-o:before {
    content: "\f003";
}
.icon-heart:before {
    content: "\f004";
}
.icon-star:before {
    content: "\f005";
}
.icon-star-o:before {
    content: "\f006";
}
.icon-user:before {
    content: "\f007";
}
.icon-film:before {
    content: "\f008";
}
.icon-th-large:before {
    content: "\f009";
}
.icon-th:before {
    content: "\f00a";
}
.icon-th-list:before {
    content: "\f00b";
}
.icon-check:before {
    content: "\f00c";
}
.icon-times:before,
.icon-remove:before,
.search-choice-close:before,
.ui-icon-closethick:before,
.ui-dialog-titlebar-close:before,
.icon-clock-os:before, 
.icon-close:before {
    content: "\f00d";
}
.icon-search-plus:before {
    content: "\f00e";
}
.icon-search-minus:before {
    content: "\f010";
}
.icon-power-off:before {
    content: "\f011";
}
.icon-signal:before {
    content: "\f012";
}
.icon-gear:before,
.icon-cog:before {
    content: "\f013";
}
.icon-trash-o:before {
    content: "\f014";
}
.icon-home:before {
    content: "\f015";
}
.icon-file-o:before {
    content: "\f016";
}
.icon-clock-o:before {
    content: "\f017";
}
.icon-road:before {
    content: "\f018";
}
.icon-download:before {
    content: "\f019";
}
.icon-arrow-circle-o-down:before {
    content: "\f01a";
}
.icon-arrow-circle-o-up:before {
    content: "\f01b";
}
.icon-inbox:before {
    content: "\f01c";
}
.icon-play-circle-o:before {
    content: "\f01d";
}
.icon-rotate-right:before,
.icon-repeat:before {
    content: "\f01e";
}
.icon-refresh:before {
    content: "\f021";
}
.icon-list-alt:before {
    content: "\f022";
}
.icon-lock:before {
    content: "\f023";
}
.icon-flag:before {
    content: "\f024";
}
.icon-headphones:before {
    content: "\f025";
}
.icon-volume-off:before {
    content: "\f026";
}
.icon-volume-down:before {
    content: "\f027";
}
.icon-volume-up:before {
    content: "\f028";
}
.icon-qrcode:before {
    content: "\f029";
}
.icon-barcode:before {
    content: "\f02a";
}
.icon-tag:before {
    content: "\f02b";
}
.icon-tags:before {
    content: "\f02c";
}
.icon-book:before {
    content: "\f02d";
}
.icon-bookmark:before {
    content: "\f02e";
}
.icon-print:before {
    content: "\f02f";
}
.icon-camera:before {
    content: "\f030";
}
.icon-font:before {
    content: "\f031";
}
.icon-bold:before {
    content: "\f032";
}
.icon-italic:before {
    content: "\f033";
}
.icon-text-height:before {
    content: "\f034";
}
.icon-text-width:before {
    content: "\f035";
}
.icon-align-left:before {
    content: "\f036";
}
.icon-align-center:before {
    content: "\f037";
}
.icon-align-right:before {
    content: "\f038";
}
.icon-align-justify:before {
    content: "\f039";
}
.icon-list:before {
    content: "\f03a";
}
.icon-dedent:before,
.icon-outdent:before {
    content: "\f03b";
}
.icon-indent:before {
    content: "\f03c";
}
.icon-video-camera:before {
    content: "\f03d";
}
.icon-photo:before,
.icon-image:before,
.icon-picture-o:before {
    content: "\f03e";
}
.icon-pencil:before {
    content: "\f040";
}
.icon-map-marker:before {
    content: "\f041";
}
.icon-adjust:before {
    content: "\f042";
}
.icon-tint:before {
    content: "\f043";
}
.icon-edit:before,
.icon-pencil-square-o:before {
    content: "\f044";
}
.icon-share-square-o:before {
    content: "\f045";
}
.icon-check-square-o:before {
    content: "\f046";
}
.icon-arrows:before {
    content: "\f047";
}
.icon-step-backward:before {
    content: "\f048";
}
.icon-fast-backward:before {
    content: "\f049";
}
.icon-backward:before {
    content: "\f04a";
}
.icon-play:before {
    content: "\f04b";
}
.icon-pause:before {
    content: "\f04c";
}
.icon-stop:before {
    content: "\f04d";
}
.icon-forward:before {
    content: "\f04e";
}
.icon-fast-forward:before {
    content: "\f050";
}
.icon-step-forward:before {
    content: "\f051";
}
.icon-eject:before {
    content: "\f052";
}
.icon-chevron-left:before {
    content: "\f053";
}
.icon-chevron-right:before {
    content: "\f054";
}
.icon-plus-circle:before {
    content: "\f055";
}
.icon-minus-circle:before {
    content: "\f056";
}
.icon-times-circle:before {
    content: "\f057";
}
.icon-check-circle:before {
    content: "\f058";
}
.icon-question-circle:before {
    content: "\f059";
}
.icon-info-circle:before {
    content: "\f05a";
}
.icon-crosshairs:before {
    content: "\f05b";
}
.icon-times-circle-o:before {
    content: "\f05c";
}
.icon-check-circle-o:before {
    content: "\f05d";
}
.icon-ban:before {
    content: "\f05e";
}
.icon-arrow-left:before {
    content: "\f060";
}
.icon-arrow-right:before {
    content: "\f061";
}
.icon-arrow-up:before {
    content: "\f062";
}
.icon-arrow-down:before {
    content: "\f063";
}
.icon-mail-forward:before,
.icon-share:before {
    content: "\f064";
}
.icon-expand:before {
    content: "\f065";
}
.icon-compress:before {
    content: "\f066";
}
.icon-plus:before {
    content: "\f067";
}
.icon-minus:before {
    content: "\f068";
}
.icon-asterisk:before {
    content: "\f069";
}
.icon-exclamation-circle:before {
    content: "\f06a";
}
.icon-gift:before {
    content: "\f06b";
}
.icon-leaf:before {
    content: "\f06c";
}
.icon-fire:before {
    content: "\f06d";
}
.icon-eye:before {
    content: "\f06e";
}
.icon-eye-slash:before {
    content: "\f070";
}
.icon-warning:before,
.icon-exclamation-triangle:before {
    content: "\f071";
}
.icon-plane:before {
    content: "\f072";
}
.icon-calendar:before {
    content: "\f073";
}
.icon-random:before {
    content: "\f074";
}
.icon-comment:before {
    content: "\f075";
}
.icon-magnet:before {
    content: "\f076";
}
.icon-chevron-up:before {
    content: "\f077";
}
.icon-chevron-down:before {
    content: "\f078";
}
.icon-retweet:before {
    content: "\f079";
}
.icon-shopping-cart:before {
    content: "\f07a";
}
.icon-folder:before {
    content: "\f07b";
}
.icon-folder-open:before {
    content: "\f07c";
}
.icon-arrows-v:before {
    content: "\f07d";
}
.icon-arrows-h:before {
    content: "\f07e";
}
.icon-bar-chart-o:before,
.icon-bar-chart:before {
    content: "\f080";
}
.icon-twitter-square:before {
    content: "\f081";
}
.icon-facebook-square:before {
    content: "\f082";
}
.icon-camera-retro:before {
    content: "\f083";
}
.icon-key:before {
    content: "\f084";
}
.icon-gears:before,
.icon-cogs:before {
    content: "\f085";
}
.icon-comments:before {
    content: "\f086";
}
.icon-thumbs-o-up:before {
    content: "\f087";
}
.icon-thumbs-o-down:before {
    content: "\f088";
}
.icon-star-half:before {
    content: "\f089";
}
.icon-heart-o:before {
    content: "\f08a";
}
.icon-sign-out:before {
    content: "\f08b";
}
.icon-linkedin-square:before {
    content: "\f08c";
}
.icon-thumb-tack:before {
    content: "\f08d";
}
.icon-external-link:before {
    content: "\f08e";
}
.icon-sign-in:before {
    content: "\f090";
}
.icon-trophy:before {
    content: "\f091";
}
.icon-github-square:before {
    content: "\f092";
}
.icon-upload:before {
    content: "\f093";
}
.icon-lemon-o:before {
    content: "\f094";
}
.icon-phone:before {
    content: "\f095";
}
.icon-square-o:before {
    content: "\f096";
}
.icon-bookmark-o:before {
    content: "\f097";
}
.icon-phone-square:before {
    content: "\f098";
}
.icon-twitter:before {
    content: "\f099";
}
.icon-facebook:before {
    content: "\f09a";
}
.icon-github:before {
    content: "\f09b";
}
.icon-unlock:before {
    content: "\f09c";
}
.icon-credit-card:before {
    content: "\f09d";
}
.icon-rss:before {
    content: "\f09e";
}
.icon-hdd-o:before {
    content: "\f0a0";
}
.icon-bullhorn:before {
    content: "\f0a1";
}
.icon-bell:before {
    content: "\f0f3";
}
.icon-certificate:before {
    content: "\f0a3";
}
.icon-hand-o-right:before {
    content: "\f0a4";
}
.icon-hand-o-left:before {
    content: "\f0a5";
}
.icon-hand-o-up:before {
    content: "\f0a6";
}
.icon-hand-o-down:before {
    content: "\f0a7";
}
.icon-arrow-circle-left:before {
    content: "\f0a8";
}
.icon-arrow-circle-right:before {
    content: "\f0a9";
}
.icon-arrow-circle-up:before {
    content: "\f0aa";
}
.icon-arrow-circle-down:before {
    content: "\f0ab";
}
.icon-globe:before {
    content: "\f0ac";
}
.icon-wrench:before {
    content: "\f0ad";
}
.icon-tasks:before {
    content: "\f0ae";
}
.icon-filter:before {
    content: "\f0b0";
}
.icon-briefcase:before {
    content: "\f0b1";
}
.icon-arrows-alt:before {
    content: "\f0b2";
}
.icon-group:before,
.icon-users:before {
    content: "\f0c0";
}
.icon-chain:before,
.icon-link:before {
    content: "\f0c1";
}
.icon-cloud:before {
    content: "\f0c2";
}
.icon-flask:before {
    content: "\f0c3";
}
.icon-cut:before,
.icon-scissors:before {
    content: "\f0c4";
}
.icon-copy:before,
.icon-files-o:before {
    content: "\f0c5";
}
.icon-paperclip:before {
    content: "\f0c6";
}
.icon-save:before,
.icon-floppy-o:before {
    content: "\f0c7";
}
.icon-square:before {
    content: "\f0c8";
}
.icon-navicon:before,
.icon-reorder:before,
.icon-bars:before {
    content: "\f0c9";
}
.icon-list-ul:before {
    content: "\f0ca";
}
.icon-list-ol:before {
    content: "\f0cb";
}
.icon-strikethrough:before {
    content: "\f0cc";
}
.icon-underline:before {
    content: "\f0cd";
}
.icon-table:before {
    content: "\f0ce";
}
.icon-magic:before {
    content: "\f0d0";
}
.icon-truck:before {
    content: "\f0d1";
}
.icon-pinterest:before {
    content: "\f0d2";
}
.icon-pinterest-square:before {
    content: "\f0d3";
}
.icon-google-plus-square:before {
    content: "\f0d4";
}
.icon-google-plus:before {
    content: "\f0d5";
}
.icon-money:before {
    content: "\f0d6";
}
.ui-accordion-header-icon.ui-icon-triangle-1-s:before,
.icon-caret-down:before {
    content: "\f0d7";
}
.icon-caret-up:before {
    content: "\f0d8";
}
.icon-caret-left:before {
    content: "\f0d9";
}
.ui-icon-triangle-1-e:before,
.icon-caret-right:before {
    content: "\f0da";
}
.icon-columns:before {
    content: "\f0db";
}
.dataTable .sorting .glyph-icon:before,
.dataTable .ui-icon-carat-2-n-s:before,
.icon-unsorted:before,
.icon-sort:before {
    content: "\f0dc";
}
.dataTable .sorting_asc .glyph-icon:before,
.icon-sort-down:before,
.icon-sort-asc:before {
    content: "\f0dd";
}
.dataTable .sorting_desc .glyph-icon:before,
.dataTable .ui-icon-triangle-1-n:before,
.icon-sort-up:before,
.icon-sort-desc:before {
    content: "\f0de";
}
.icon-envelope:before {
    content: "\f0e0";
}
.icon-linkedin:before {
    content: "\f0e1";
}
.icon-rotate-left:before,
.icon-undo:before {
    content: "\f0e2";
}
.icon-legal:before,
.icon-gavel:before {
    content: "\f0e3";
}
.icon-dashboard:before,
.icon-tachometer:before {
    content: "\f0e4";
}
.icon-comment-o:before {
    content: "\f0e5";
}
.icon-comments-o:before {
    content: "\f0e6";
}
.icon-flash:before,
.icon-bolt:before {
    content: "\f0e7";
}
.icon-sitemap:before {
    content: "\f0e8";
}
.icon-umbrella:before {
    content: "\f0e9";
}
.icon-paste:before,
.icon-clipboard:before {
    content: "\f0ea";
}
.icon-lightbulb-o:before {
    content: "\f0eb";
}
.icon-exchange:before {
    content: "\f0ec";
}
.icon-cloud-download:before {
    content: "\f0ed";
}
.icon-cloud-upload:before {
    content: "\f0ee";
}
.icon-user-md:before {
    content: "\f0f0";
}
.icon-stethoscope:before {
    content: "\f0f1";
}
.icon-suitcase:before {
    content: "\f0f2";
}
.icon-bell-o:before {
    content: "\f0a2";
}
.icon-coffee:before {
    content: "\f0f4";
}
.icon-cutlery:before {
    content: "\f0f5";
}
.icon-file-text-o:before {
    content: "\f0f6";
}
.icon-building-o:before {
    content: "\f0f7";
}
.icon-hospital-o:before {
    content: "\f0f8";
}
.icon-ambulance:before {
    content: "\f0f9";
}
.icon-medkit:before {
    content: "\f0fa";
}
.icon-fighter-jet:before {
    content: "\f0fb";
}
.icon-beer:before {
    content: "\f0fc";
}
.icon-h-square:before {
    content: "\f0fd";
}
.icon-plus-square:before {
    content: "\f0fe";
}
.icon-angle-double-left:before {
    content: "\f100";
}
.icon-angle-double-right:before {
    content: "\f101";
}
.icon-angle-double-up:before {
    content: "\f102";
}
.icon-angle-double-down:before {
    content: "\f103";
}
.icon-angle-left:before {
    content: "\f104";
}
#page-sidebar li a.sf-with-ul:after,
.icon-angle-right:before {
    content: "\f105";
}
.icon-angle-up:before {
    content: "\f106";
}
.icon-angle-down:before {
    content: "\f107";
}
.icon-desktop:before {
    content: "\f108";
}
.icon-laptop:before {
    content: "\f109";
}
.icon-tablet:before {
    content: "\f10a";
}
.icon-mobile-phone:before,
.icon-mobile:before {
    content: "\f10b";
}
.icon-circle-o:before {
    content: "\f10c";
}
.icon-quote-left:before {
    content: "\f10d";
}
.icon-quote-right:before {
    content: "\f10e";
}
.icon-spinner:before {
    content: "\f110";
}
#page-sidebar li ul li a:before,
.icon-circle:before {
    content: "\f111";
}
.icon-mail-reply:before,
.icon-reply:before {
    content: "\f112";
}
.icon-github-alt:before {
    content: "\f113";
}
.icon-folder-o:before {
    content: "\f114";
}
.icon-folder-open-o:before {
    content: "\f115";
}
.icon-smile-o:before {
    content: "\f118";
}
.icon-frown-o:before {
    content: "\f119";
}
.icon-meh-o:before {
    content: "\f11a";
}
.icon-gamepad:before {
    content: "\f11b";
}
.icon-keyboard-o:before {
    content: "\f11c";
}
.icon-flag-o:before {
    content: "\f11d";
}
.icon-flag-checkered:before {
    content: "\f11e";
}
.icon-terminal:before {
    content: "\f120";
}
.icon-code:before {
    content: "\f121";
}
.icon-mail-reply-all:before,
.icon-reply-all:before {
    content: "\f122";
}
.icon-star-half-empty:before,
.icon-star-half-full:before,
.icon-star-half-o:before {
    content: "\f123";
}
.icon-location-arrow:before {
    content: "\f124";
}
.icon-crop:before {
    content: "\f125";
}
.icon-code-fork:before {
    content: "\f126";
}
.icon-unlink:before,
.icon-chain-broken:before {
    content: "\f127";
}
.icon-question:before {
    content: "\f128";
}
.icon-info:before {
    content: "\f129";
}
.icon-exclamation:before {
    content: "\f12a";
}
.icon-superscript:before {
    content: "\f12b";
}
.icon-subscript:before {
    content: "\f12c";
}
.icon-eraser:before {
    content: "\f12d";
}
.icon-puzzle-piece:before {
    content: "\f12e";
}
.icon-microphone:before {
    content: "\f130";
}
.icon-microphone-slash:before {
    content: "\f131";
}
.icon-shield:before {
    content: "\f132";
}
.icon-calendar-o:before {
    content: "\f133";
}
.icon-fire-extinguisher:before {
    content: "\f134";
}
.icon-rocket:before {
    content: "\f135";
}
.icon-maxcdn:before {
    content: "\f136";
}
.ui-icon-circle-triangle-w:before,
.icon-chevron-circle-left:before {
    content: "\f137";
}
.ui-icon-circle-triangle-e:before,
.icon-chevron-circle-right:before {
    content: "\f138";
}
.icon-chevron-circle-up:before {
    content: "\f139";
}
.icon-chevron-circle-down:before {
    content: "\f13a";
}
.icon-html5:before {
    content: "\f13b";
}
.icon-css3:before {
    content: "\f13c";
}
.icon-anchor:before {
    content: "\f13d";
}
.icon-unlock-alt:before {
    content: "\f13e";
}
.icon-bullseye:before {
    content: "\f140";
}
.icon-ellipsis-h:before {
    content: "\f141";
}
.icon-ellipsis-v:before {
    content: "\f142";
}
.icon-rss-square:before {
    content: "\f143";
}
.icon-play-circle:before {
    content: "\f144";
}
.icon-ticket:before {
    content: "\f145";
}
.icon-minus-square:before {
    content: "\f146";
}
.icon-minus-square-o:before {
    content: "\f147";
}
.icon-level-up:before {
    content: "\f148";
}
.icon-level-down:before {
    content: "\f149";
}
.icon-check-square:before {
    content: "\f14a";
}
.icon-pencil-square:before {
    content: "\f14b";
}
.icon-external-link-square:before {
    content: "\f14c";
}
.icon-share-square:before {
    content: "\f14d";
}
.icon-compass:before {
    content: "\f14e";
}
.icon-toggle-down:before,
.icon-caret-square-o-down:before {
    content: "\f150";
}
.icon-toggle-up:before,
.icon-caret-square-o-up:before {
    content: "\f151";
}
.icon-toggle-right:before,
.icon-caret-square-o-right:before {
    content: "\f152";
}
.icon-euro:before,
.icon-eur:before {
    content: "\f153";
}
.icon-gbp:before {
    content: "\f154";
}
.icon-dollar:before,
.icon-usd:before {
    content: "\f155";
}
.icon-rupee:before,
.icon-inr:before {
    content: "\f156";
}
.icon-cny:before,
.icon-rmb:before,
.icon-yen:before,
.icon-jpy:before {
    content: "\f157";
}
.icon-ruble:before,
.icon-rouble:before,
.icon-rub:before {
    content: "\f158";
}
.icon-won:before,
.icon-krw:before {
    content: "\f159";
}
.icon-bitcoin:before,
.icon-btc:before {
    content: "\f15a";
}
.icon-file:before {
    content: "\f15b";
}
.icon-file-text:before {
    content: "\f15c";
}
.icon-sort-alpha-asc:before {
    content: "\f15d";
}
.icon-sort-alpha-desc:before {
    content: "\f15e";
}
.icon-sort-amount-asc:before {
    content: "\f160";
}
.icon-sort-amount-desc:before {
    content: "\f161";
}
.icon-sort-numeric-asc:before {
    content: "\f162";
}
.icon-sort-numeric-desc:before {
    content: "\f163";
}
.icon-thumbs-up:before {
    content: "\f164";
}
.icon-thumbs-down:before {
    content: "\f165";
}
.icon-youtube-square:before {
    content: "\f166";
}
.icon-youtube:before {
    content: "\f167";
}
.icon-xing:before {
    content: "\f168";
}
.icon-xing-square:before {
    content: "\f169";
}
.icon-youtube-play:before {
    content: "\f16a";
}
.icon-dropbox:before {
    content: "\f16b";
}
.icon-stack-overflow:before {
    content: "\f16c";
}
.icon-instagram:before {
    content: "\f16d";
}
.icon-flickr:before {
    content: "\f16e";
}
.icon-adn:before {
    content: "\f170";
}
.icon-bitbucket:before {
    content: "\f171";
}
.icon-bitbucket-square:before {
    content: "\f172";
}
.icon-tumblr:before {
    content: "\f173";
}
.icon-tumblr-square:before {
    content: "\f174";
}
.icon-long-arrow-down:before {
    content: "\f175";
}
.icon-long-arrow-up:before {
    content: "\f176";
}
.icon-long-arrow-left:before {
    content: "\f177";
}
.icon-long-arrow-right:before {
    content: "\f178";
}
.icon-apple:before {
    content: "\f179";
}
.icon-windows:before {
    content: "\f17a";
}
.icon-android:before {
    content: "\f17b";
}
.icon-linux:before {
    content: "\f17c";
}
.icon-dribbble:before {
    content: "\f17d";
}
.icon-skype:before {
    content: "\f17e";
}
.icon-foursquare:before {
    content: "\f180";
}
.icon-trello:before {
    content: "\f181";
}
.icon-female:before {
    content: "\f182";
}
.icon-male:before {
    content: "\f183";
}
.icon-gittip:before {
    content: "\f184";
}
.icon-sun-o:before {
    content: "\f185";
}
.icon-moon-o:before {
    content: "\f186";
}
.icon-archive:before {
    content: "\f187";
}
.icon-bug:before {
    content: "\f188";
}
.icon-vk:before {
    content: "\f189";
}
.icon-weibo:before {
    content: "\f18a";
}
.icon-renren:before {
    content: "\f18b";
}
.icon-pagelines:before {
    content: "\f18c";
}
.icon-stack-exchange:before {
    content: "\f18d";
}
.icon-arrow-circle-o-right:before {
    content: "\f18e";
}
.icon-arrow-circle-o-left:before {
    content: "\f190";
}
.icon-toggle-left:before,
.icon-caret-square-o-left:before {
    content: "\f191";
}
.icon-dot-circle-o:before {
    content: "\f192";
}
.icon-wheelchair:before {
    content: "\f193";
}
.icon-vimeo-square:before {
    content: "\f194";
}
.icon-turkish-lira:before,
.icon-try:before {
    content: "\f195";
}
.icon-plus-square-o:before {
    content: "\f196";
}
.icon-space-shuttle:before {
    content: "\f197";
}
.icon-slack:before {
    content: "\f198";
}
.icon-envelope-square:before {
    content: "\f199";
}
.icon-wordpress:before {
    content: "\f19a";
}
.icon-openid:before {
    content: "\f19b";
}
.icon-institution:before,
.icon-bank:before,
.icon-university:before {
    content: "\f19c";
}
.icon-mortar-board:before,
.icon-graduation-cap:before {
    content: "\f19d";
}
.icon-yahoo:before {
    content: "\f19e";
}
.icon-google:before {
    content: "\f1a0";
}
.icon-reddit:before {
    content: "\f1a1";
}
.icon-reddit-square:before {
    content: "\f1a2";
}
.icon-stumbleupon-circle:before {
    content: "\f1a3";
}
.icon-stumbleupon:before {
    content: "\f1a4";
}
.icon-delicious:before {
    content: "\f1a5";
}
.icon-digg:before {
    content: "\f1a6";
}
.icon-pied-piper:before {
    content: "\f1a7";
}
.icon-pied-piper-alt:before {
    content: "\f1a8";
}
.icon-drupal:before {
    content: "\f1a9";
}
.icon-joomla:before {
    content: "\f1aa";
}
.icon-language:before {
    content: "\f1ab";
}
.icon-fax:before {
    content: "\f1ac";
}
.icon-building:before {
    content: "\f1ad";
}
.icon-child:before {
    content: "\f1ae";
}
.icon-paw:before {
    content: "\f1b0";
}
.icon-spoon:before {
    content: "\f1b1";
}
.icon-cube:before {
    content: "\f1b2";
}
.icon-cubes:before {
    content: "\f1b3";
}
.icon-behance:before {
    content: "\f1b4";
}
.icon-behance-square:before {
    content: "\f1b5";
}
.icon-steam:before {
    content: "\f1b6";
}
.icon-steam-square:before {
    content: "\f1b7";
}
.icon-recycle:before {
    content: "\f1b8";
}
.icon-automobile:before,
.icon-car:before {
    content: "\f1b9";
}
.icon-cab:before,
.icon-taxi:before {
    content: "\f1ba";
}
.icon-tree:before {
    content: "\f1bb";
}
.icon-spotify:before {
    content: "\f1bc";
}
.icon-deviantart:before {
    content: "\f1bd";
}
.icon-soundcloud:before {
    content: "\f1be";
}
.icon-database:before {
    content: "\f1c0";
}
.icon-file-pdf-o:before {
    content: "\f1c1";
}
.icon-file-word-o:before {
    content: "\f1c2";
}
.icon-file-excel-o:before {
    content: "\f1c3";
}
.icon-file-powerpoint-o:before {
    content: "\f1c4";
}
.icon-file-photo-o:before,
.icon-file-picture-o:before,
.icon-file-image-o:before {
    content: "\f1c5";
}
.icon-file-zip-o:before,
.icon-file-archive-o:before {
    content: "\f1c6";
}
.icon-file-sound-o:before,
.icon-file-audio-o:before {
    content: "\f1c7";
}
.icon-file-movie-o:before,
.icon-file-video-o:before {
    content: "\f1c8";
}
.icon-file-code-o:before {
    content: "\f1c9";
}
.icon-vine:before {
    content: "\f1ca";
}
.icon-codepen:before {
    content: "\f1cb";
}
.icon-jsfiddle:before {
    content: "\f1cc";
}
.icon-life-bouy:before,
.icon-life-buoy:before,
.icon-life-saver:before,
.icon-support:before,
.icon-life-ring:before {
    content: "\f1cd";
}
.icon-circle-o-notch:before {
    content: "\f1ce";
}
.icon-ra:before,
.icon-rebel:before {
    content: "\f1d0";
}
.icon-ge:before,
.icon-empire:before {
    content: "\f1d1";
}
.icon-git-square:before {
    content: "\f1d2";
}
.icon-git:before {
    content: "\f1d3";
}
.icon-hacker-news:before {
    content: "\f1d4";
}
.icon-tencent-weibo:before {
    content: "\f1d5";
}
.icon-qq:before {
    content: "\f1d6";
}
.icon-wechat:before,
.icon-weixin:before {
    content: "\f1d7";
}
.icon-send:before,
.icon-paper-plane:before {
    content: "\f1d8";
}
.icon-send-o:before,
.icon-paper-plane-o:before {
    content: "\f1d9";
}
.icon-history:before {
    content: "\f1da";
}
.icon-circle-thin:before {
    content: "\f1db";
}
.icon-header:before {
    content: "\f1dc";
}
.icon-paragraph:before {
    content: "\f1dd";
}
.icon-sliders:before {
    content: "\f1de";
}
.icon-share-alt:before {
    content: "\f1e0";
}
.icon-share-alt-square:before {
    content: "\f1e1";
}
.icon-bomb:before {
    content: "\f1e2";
}
.icon-soccer-ball-o:before,
.icon-futbol-o:before {
    content: "\f1e3";
}
.icon-tty:before {
    content: "\f1e4";
}
.icon-binoculars:before {
    content: "\f1e5";
}
.icon-plug:before {
    content: "\f1e6";
}
.icon-slideshare:before {
    content: "\f1e7";
}
.icon-twitch:before {
    content: "\f1e8";
}
.icon-yelp:before {
    content: "\f1e9";
}
.icon-newspaper-o:before {
    content: "\f1ea";
}
.icon-wifi:before {
    content: "\f1eb";
}
.icon-calculator:before {
    content: "\f1ec";
}
.icon-paypal:before {
    content: "\f1ed";
}
.icon-google-wallet:before {
    content: "\f1ee";
}
.icon-cc-visa:before {
    content: "\f1f0";
}
.icon-cc-mastercard:before {
    content: "\f1f1";
}
.icon-cc-discover:before {
    content: "\f1f2";
}
.icon-cc-amex:before {
    content: "\f1f3";
}
.icon-cc-paypal:before {
    content: "\f1f4";
}
.icon-cc-stripe:before {
    content: "\f1f5";
}
.icon-bell-slash:before {
    content: "\f1f6";
}
.icon-bell-slash-o:before {
    content: "\f1f7";
}
.icon-trash:before {
    content: "\f1f8";
}
.icon-copyright:before {
    content: "\f1f9";
}
.icon-at:before {
    content: "\f1fa";
}
.icon-eyedropper:before {
    content: "\f1fb";
}
.icon-paint-brush:before {
    content: "\f1fc";
}
.icon-birthday-cake:before {
    content: "\f1fd";
}
.icon-area-chart:before {
    content: "\f1fe";
}
.icon-pie-chart:before {
    content: "\f200";
}
.icon-line-chart:before {
    content: "\f201";
}
.icon-lastfm:before {
    content: "\f202";
}
.icon-lastfm-square:before {
    content: "\f203";
}
.icon-toggle-off:before {
    content: "\f204";
}
.icon-toggle-on:before {
    content: "\f205";
}
.icon-bicycle:before {
    content: "\f206";
}
.icon-bus:before {
    content: "\f207";
}
.icon-ioxhost:before {
    content: "\f208";
}
.icon-angellist:before {
    content: "\f209";
}
.icon-cc:before {
    content: "\f20a";
}
.icon-shekel:before,
.icon-sheqel:before,
.icon-ils:before {
    content: "\f20b";
}
.icon-meanpath:before {
    content: "\f20c";
}