/* Boilerplate  */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden] {
    display: none;
}
html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}
body {
    margin: 0;
}
abbr[title] {
    border-bottom: 1px dotted;
}
b,
strong {
    font-weight: bold;
}
dfn {
    font-style: italic;
}
hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}
mark {
    color: #000;
    background: #ff0;
}
code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    font-size: 1em;
}
pre {
    white-space: pre-wrap;
}
q {
    quotes: '\201C''\201D''\2018''\2019';
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sup {
    top: -.5em;
}
sub {
    bottom: -.25em;
}
img {
    border: 0;
}
svg:not(:root) {
    overflow: hidden;
}
figure {
    margin: 0;
}
fieldset {
    margin: 0 2px;
    padding: .35em .625em .75em;
    border: 1px solid #c0c0c0;
}
legend {
    padding: 0;
    border: 0;
}
button {
    margin: 0;
}
button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
}
button,
input {
    line-height: normal;
}
button,
select {
    text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    cursor: pointer;
    -webkit-appearance: button;
}
button[disabled] {
    cursor: default;
}
input[type='checkbox'],
input[type='radio'] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}
input[type='search'] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: textfield;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}
textarea {
    overflow: auto;
    vertical-align: top;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
@media print {
    * {
        color: #000 !important;
        background: transparent !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    a[href]:after {
        content: ' (' attr(href)')';
    }
    abbr[title]:after {
        content: ' (' attr(title)')';
    }
    .ir a:after,
    a[href^='javascript:']:after,
    a[href^='#']:after {
        content: '';
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    @page {
        margin: 2cm .5cm;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    .jGrowl {
        display: none;
    }
}
small {
    font-size: 85%;
}
a {
    text-decoration: none;
}
a,
a:visited,
a:focus,
a:active,
*:visited,
*:focus,
*:active {
    outline: none;
}
html {
    font-size: 62.5%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.jqstooltip {
    box-sizing: content-box;
}
