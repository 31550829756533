@font-face {
  font-family: 'spinnericon';
  src: url('../fonts/delight/spinnericon.eot?39661457');
  src: url('../fonts/delight/spinnericon.eot?39661457#iefix') format('embedded-opentype'),
       url('../fonts/delight/spinnericon.woff?39661457') format('woff'),
       url('../fonts/delight/spinnericon.ttf?39661457') format('truetype'),
       url('../fonts/delight/spinnericon.svg?39661457#spinnericon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-spin-']:before,
[class*=' icon-spin-']:before {
    font-family: 'spinnericon';
}

.icon-spin-1:before { content: '\e80b'; }
.icon-spin-2:before { content: '\e80a'; }
.icon-spin-3:before { content: '\e809'; }
.icon-spin-4:before { content: '\e808'; }
.icon-spin-5:before { content: '\e807'; }
.icon-spin-6:before { content: '\e806'; }