/* ------------------------------------------------------------------------
    This you can edit.
------------------------------------------------------------------------- */
/* ----------------------------------
    Default Theme
----------------------------------- */

div.pp_default .pp_top,
div.pp_default .pp_top .pp_middle,
div.pp_default .pp_top .pp_left,
div.pp_default .pp_top .pp_right,
div.pp_default .pp_bottom,
div.pp_default .pp_bottom .pp_left,
div.pp_default .pp_bottom .pp_middle,
div.pp_default .pp_bottom .pp_right {
    height: 13px;
}
div.pp_default .pp_top .pp_left {
    background: url(images/default/sprite.png) -78px -93px no-repeat;
}
/* Top left corner */

div.pp_default .pp_top .pp_middle {
    background: url(images/default/sprite_x.png) top left repeat-x;
}
/* Top pattern/color */

div.pp_default .pp_top .pp_right {
    background: url(images/default/sprite.png) -112px -93px no-repeat;
}
/* Top right corner */

div.pp_default .pp_content .ppt {
    color: #f8f8f8;
}
div.pp_default .pp_content_container .pp_left {
    padding-left: 13px;
    background: url(images/default/sprite_y.png) -7px 0 repeat-y;
}
div.pp_default .pp_content_container .pp_right {
    padding-right: 13px;
    background: url(images/default/sprite_y.png) top right repeat-y;
}
div.pp_default .pp_content {
    background-color: #fff;
}
/* Content background */

div.pp_default .pp_next:hover {
    cursor: pointer;
    background: url(images/default/sprite_next.png) center right no-repeat;
}
/* Next button */

div.pp_default .pp_previous:hover {
    cursor: pointer;
    background: url(images/default/sprite_prev.png) center left no-repeat;
}
/* Previous button */

div.pp_default .pp_expand {
    width: 28px;
    height: 28px;
    cursor: pointer;
    background: url(images/default/sprite.png) 0 -29px no-repeat;
}
/* Expand button */

div.pp_default .pp_expand:hover {
    cursor: pointer;
    background: url(images/default/sprite.png) 0 -56px no-repeat;
}
/* Expand button hover */

div.pp_default .pp_contract {
    width: 28px;
    height: 28px;
    cursor: pointer;
    background: url(images/default/sprite.png) 0 -84px no-repeat;
}
/* Contract button */

div.pp_default .pp_contract:hover {
    cursor: pointer;
    background: url(images/default/sprite.png) 0 -113px no-repeat;
}
/* Contract button hover */

div.pp_default .pp_close {
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: url(images/default/sprite.png) 2px 1px no-repeat;
}
/* Close button */

div.pp_default #pp_full_res .pp_inline {
    color: #000;
}
div.pp_default .pp_gallery ul li a {
    border: 1px solid #aaa;
    background: url(images/default/default_thumb.png) center center #f8f8f8;
}
div.pp_default .pp_gallery ul li a:hover,
div.pp_default .pp_gallery ul li.selected a {
    border-color: #fff;
}
div.pp_default .pp_social {
    margin-top: 7px;
}
div.pp_default .pp_gallery a.pp_arrow_previous,
div.pp_default .pp_gallery a.pp_arrow_next {
    position: static;
    left: auto;
}
div.pp_default .pp_nav .pp_play,
div.pp_default .pp_nav .pp_pause {
    width: 30px;
    height: 30px;
    background: url(images/default/sprite.png) -51px 1px no-repeat;
}
div.pp_default .pp_nav .pp_pause {
    background-position: -51px -29px;
}
div.pp_default .pp_details {
    position: relative;
}
div.pp_default a.pp_arrow_previous,
div.pp_default a.pp_arrow_next {
    width: 20px;
    height: 20px;
    margin: 4px 0 0 0;
    background: url(images/default/sprite.png) -31px -3px no-repeat;
}
div.pp_default a.pp_arrow_next {
    left: 52px;
    background-position: -82px -3px;
}
/* The next arrow in the bottom nav */

div.pp_default .pp_content_container .pp_details {
    margin-top: 5px;
}
div.pp_default .pp_nav {
    position: relative;
    clear: none;
    width: 110px;
    height: 30px;
}
div.pp_default .pp_nav .currentTextHolder {
    font-family: Georgia;
    font-size: 11px;
    font-style: italic;
    line-height: 25px;
    position: absolute;
    top: 2px;
    left: 75px;
    margin: 0;
    padding: 0 0 0 10px;
    color: #999;
}
div.pp_default .pp_close:hover,
div.pp_default .pp_nav .pp_play:hover,
div.pp_default .pp_nav .pp_pause:hover,
div.pp_default .pp_arrow_next:hover,
div.pp_default .pp_arrow_previous:hover {
    opacity: .7;
}
div.pp_default .pp_description {
    font-size: 11px;
    font-weight: bold;
    line-height: 14px;
    margin: 5px 50px 5px 0;
}
div.pp_default .pp_bottom .pp_left {
    background: url(images/default/sprite.png) -78px -127px no-repeat;
}
/* Bottom left corner */

div.pp_default .pp_bottom .pp_middle {
    background: url(images/default/sprite_x.png) bottom left repeat-x;
}
/* Bottom pattern/color */

div.pp_default .pp_bottom .pp_right {
    background: url(images/default/sprite.png) -112px -127px no-repeat;
}
/* Bottom right corner */

div.pp_default .pp_loaderIcon {
    background: url(images/default/loader.gif) center center no-repeat;
}
/* Loader icon */
/* ------------------------------------------------------------------------
    DO NOT CHANGE
------------------------------------------------------------------------- */

div.pp_pic_holder a:focus {
    outline: none;
}
div.pp_overlay {
    position: absolute;
    z-index: 9500;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    background: #000;
}
div.pp_pic_holder {
    position: absolute;
    z-index: 10000;
    display: none;
    width: 100px;
}
.pp_top {
    position: relative;
    height: 20px;
}
* html .pp_top {
    padding: 0 20px;
}
.pp_top .pp_left {
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
}
.pp_top .pp_middle {
    position: absolute;
    right: 20px;
    left: 20px;
    height: 20px;
}
* html .pp_top .pp_middle {
    position: static;
    left: 0;
}
.pp_top .pp_right {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: 20px;
    height: 20px;
}
.pp_content {
    min-width: 40px;
    height: 40px;
}
* html .pp_content {
    width: 40px;
}
.pp_fade {
    display: none;
}
.pp_content_container {
    position: relative;
    width: 100%;
    text-align: left;
}
.pp_content_container .pp_left {
    padding-left: 20px;
}
.pp_content_container .pp_right {
    padding-right: 20px;
}
.pp_content_container .pp_details {
    float: left;
    margin: 10px 0 2px 0;
}
.pp_description {
    display: none;
    margin: 0;
}
.pp_social {
    float: left;
    margin: 0;
}
.pp_social .facebook {
    float: left;
    overflow: hidden;
    width: 55px;
    margin-left: 5px;
}
.pp_social .twitter {
    float: left;
}
.pp_nav {
    float: left;
    clear: right;
    margin: 3px 10px 0 0;
}
.pp_nav p {
    float: left;
    margin: 2px 4px;
    white-space: nowrap;
}
.pp_nav .pp_play,
.pp_nav .pp_pause {
    float: left;
    margin-right: 4px;
    text-indent: -10000px;
}
a.pp_arrow_previous,
a.pp_arrow_next {
    display: block;
    float: left;
    overflow: hidden;
    width: 14px;
    height: 15px;
    margin-top: 3px;
    text-indent: -10000px;
}
.pp_hoverContainer {
    position: absolute;
    z-index: 2000;
    top: 0;
    width: 100%;
}
.pp_gallery {
    position: absolute;
    z-index: 10000;
    left: 50%;
    display: none;
    margin-top: -50px;
}
.pp_gallery div {
    position: relative;
    float: left;
    overflow: hidden;
}
.pp_gallery ul {
    position: relative;
    float: left;
    height: 35px;
    margin: 0 0 0 5px;
    padding: 0;
    white-space: nowrap;
}
.pp_gallery ul a {
    display: block;
    float: left;
    overflow: hidden;
    height: 33px;
    border: 1px #000 solid;
    border: 1px rgba(0, 0, 0, .5) solid;
}
.pp_gallery ul a:hover,
.pp_gallery li.selected a {
    border-color: #fff;
}
.pp_gallery ul a img {
    border: 0;
}
.pp_gallery li {
    display: block;
    float: left;
    margin: 0 5px 0 0;
    padding: 0;
}
.pp_gallery li.default a {
    display: block;
    width: 50px;
    height: 33px;
    background: url(images/facebook/default_thumbnail.gif) 0 0 no-repeat;
}
.pp_gallery li.default a img {
    display: none;
}
.pp_gallery .pp_arrow_previous,
.pp_gallery .pp_arrow_next {
    margin-top: 7px !important;
}
a.pp_expand,
a.pp_contract {
    position: absolute;
    z-index: 20000;
    top: 10px;
    right: 30px;
    display: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    text-indent: -10000px;
}
a.pp_close {
    line-height: 22px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    text-indent: -10000px;
}
.pp_bottom {
    position: relative;
    height: 20px;
}
* html .pp_bottom {
    padding: 0 20px;
}
.pp_bottom .pp_left {
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
}
.pp_bottom .pp_middle {
    position: absolute;
    right: 20px;
    left: 20px;
    height: 20px;
}
* html .pp_bottom .pp_middle {
    position: static;
    left: 0;
}
.pp_bottom .pp_right {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    width: 20px;
    height: 20px;
}
.pp_loaderIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 24px;
    height: 24px;
    margin: -12px 0 0 -12px;
}
#pp_full_res {
    line-height: 1 !important;
}
#pp_full_res .pp_inline {
    text-align: left;
}
#pp_full_res .pp_inline p {
    margin: 0 0 15px 0;
}
div.ppt {
    font-size: 17px;
    z-index: 9999;
    display: none;
    margin: 0 0 5px 15px;
    color: #fff;
}
