/* ========================================================================
 * bootstrap-switch - v3.0.2
 * http://www.bootstrap-switch.org
 * ========================================================================
 * Copyright 2012-2013 Mattia Larentis
 *
 * ========================================================================
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ========================================================================
 */

.bootstrap-switch {
    line-height: 8px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    min-width: 100px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
    vertical-align: middle;
    border: 1px solid;
    border-color: #ccc;
    border-radius: 4px;
}
.bootstrap-switch.bootstrap-switch-mini {
    min-width: 71px;
}
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-mini .bootstrap-switch-label {
    font-size: 10px;
    line-height: 9px;
    padding-top: 4px;
    padding-bottom: 4px;
}
.bootstrap-switch.bootstrap-switch-small {
    min-width: 79px;
}
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
    font-size: 12px;
    line-height: 18px;
    padding-top: 3px;
    padding-bottom: 3px;
}
.bootstrap-switch.bootstrap-switch-large {
    min-width: 120px;
}
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-large .bootstrap-switch-label {
    font-size: 14px;
    line-height: normal;
    padding-top: 9px;
    padding-bottom: 9px;
}
.bootstrap-switch.bootstrap-switch-animate .bootstrap-switch-container {
    -webkit-transition: margin-left .5s;
    transition: margin-left .5s;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-container {
    margin-left: 0;
}
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-label {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-container {
    margin-left: -50%;
}
.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-label {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-container {
    margin-left: -25%;
}
.bootstrap-switch.bootstrap-switch-disabled,
.bootstrap-switch.bootstrap-switch-readonly,
.bootstrap-switch.bootstrap-switch-indeterminate {
    cursor: default !important;
    opacity: .5;
    filter: alpha(opacity=50);
}
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
    cursor: default !important;
}
.bootstrap-switch.bootstrap-switch-focused {
    border-color: #ccc;
    outline: 0;
}
.bootstrap-switch .bootstrap-switch-container {
    top: 0;
    display: inline-block;
    width: 150%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    border-radius: 4px;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off,
.bootstrap-switch .bootstrap-switch-label {
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    display: inline-block !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
}
.bootstrap-switch .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off {
    z-index: 1;
    width: 33.333333333%;
    text-align: center;
}
.bootstrap-switch .bootstrap-switch-label {
    z-index: 100;
    width: 33.333333333%;
    margin-top: -1px;
    margin-bottom: -1px;
    text-align: center;
    color: #333;
    background: #fff;
}
.bootstrap-switch input[type='radio'],
.bootstrap-switch input[type='checkbox'] {
    position: absolute !important;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}
.bootstrap-switch input[type='radio'].form-control,
.bootstrap-switch input[type='checkbox'].form-control {
    height: auto;
}

/* Input switch alternate */

div.switch-toggle {
    display: block;
    height: 24px;
    width: 48px;
    position: relative;
    cursor: pointer;
    background: #fff;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
    border-radius: 50px;
}
div.switch-toggle:after {
    content: '';
    display: block;
    height: 18px;
    width: 18px;
    background: #fff;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}
div.switch-toggle:before {
    content: '';
    position: absolute;
    right: 1px;
    top: 12px;
    color: #fff;
}
div.switch-toggle:hover:after {
    left: 5px;
}
div.switch-toggle.switch-on:before {
    content: '';
    right: 40px;
}
.switch-toggle.switch-on {
    background: #0c0;
}
div.switch-toggle.switch-on:after {
    left: 26px;
}
div.switch-toggle.switch-on:hover:after {
    left: 24px;
}
