/* Images */

.carousel-inner > .item > img,
.carousel-inner > .item > a > img,
.img-responsive {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
}
.img-full {
    display: block;
    width: 100%;
    height: 100%;
}
.img-rounded {
    border-radius: 6px;
}
.thumbnail,
.img-thumbnail {
    line-height: 1.428571429;
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    background-color: #fff;
}
.thumbnail img,
.img-thumbnail img {
    display: block;
}
.img-circle {
    border-radius: 50%;
}
.img-bordered {
    padding: 3px;
    border-width: 1px;
    border-style: solid;
    background: #fff;
}
/* Icon helper */

.icon-helper {
    line-height: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 36px;
    height: 36px;
}
.icon-br {
    top: auto;
    right: 0;
    left: auto;
}
.icon-tr {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
}
.icon-center {
    font-size: 50px;
    line-height: 50px;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
}
/* Hover effects */

.thumbnail-box {
    position: relative;
    overflow: hidden;
    margin: 0 0 20px;
}
.thumbnail-box .thumb-link,
.thumbnail-box .thumb-content,
.thumbnail-box .thumb-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.thumbnail-box .thumb-link {
    z-index: 25;
}
.thumbnail-box .thumb-content {
    z-index: 20;
    display: none;
}
.thumbnail-box .thumb-overlay {
    z-index: 15;
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity: 0);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    visibility: hidden;
}
.thumbnail-box .thumb-overlay.bg-black {
    background: #000;
}
.thumbnail-box img {
    display: block;
    width: 100%;
    position: relative;
    z-index: 10;
    transition: .3s all linear;
    -webkit-transition: .3s all linear;
}
.thumbnail-box:hover img {
    transform: matrix(1.1, 0, 0, 1.1, 0, 0);
    -ms-transform: matrix(1.1, 0, 0, 1.1, 0, 0);
    -webkit-transform: matrix(1.1, 0, 0, 1.1, 0, 0);
}
.thumbnail-box:hover .thumb-overlay {
    opacity: 0.80;
    -moz-opacity: .80;
    filter: alpha(opacity: 80);
    visibility: visible;
}
.thumbnail-box:hover .thumb-content {
    display: block;
}
/* Thumbnail heading */

.thumb-heading {
    text-align: center;
    font-size: 15px;
    text-transform: uppercase;
}
.thumbnail-box .thumb-heading {
    color: #fff;
}
.thumb-heading small {
    display: block;
    font-size: 13px;
    opacity: 0.65;
    padding: 5px 0 0;
    -moz-opacity: .65;
    filter: alpha(opacity: 65);
    text-transform: none;
}
/* Thumbnail buttons */

.thumbnail-box .thumb-btn {
    text-align: center;
    padding: 15px;
}
.thumb-btn.zoomIn {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
}
/* Thumbnail inverse */

.thumbnail-box-inverse .thumb-overlay {
    display: block;
    opacity: 0.3;
    -moz-opacity: .30;
    filter: alpha(opacity: 30);
    visibility: visible;
}
.thumbnail-box-inverse:hover .thumb-overlay {
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity: 0);
    visibility: hidden;
}
.thumbnail-box-inverse .thumb-content {
    display: block;
}
.thumbnail-box-inverse:hover .thumb-content {
    display: none;
}
/* Thumbnail pane */

.thumb-pane {
    border: transparent solid 1px;
    border-top: 0;
    padding: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.thumbnail-box + .thumb-pane {
    margin-top: -20px;
    margin-bottom: 20px;
}

.owl-item .thumb-pane {
    margin: 0;
}

.owl-item .thumbnail-box {
    margin-bottom: 0;
}

/* Thumbnail wrapper */

.thumbnail-box-wrapper {}

/* Image box */

.image-box {
    position: relative;
    overflow: hidden;
    max-height: 300px;
    padding: 0;
}
.image-box .image-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    text-align: center;
}
