/* Header */

#page-header {
    position: fixed;
    width: 100%;
    padding-left: 300px;
    left: 0;
    top: 0;
    height: 60px;
    z-index: 100;
}

#header-logo {
    height: 60px;
    position: relative;
}

#header-logo .logo-content-big,
.logo-content-small {
    background: url('../images/logo-admin.png') left 50% no-repeat;
    text-indent: -999em;
    position: absolute;
    height: 43px;
    width: 193px;
    left: 10px;
    top: 50%;
    margin-top: -22px;
}
.logo-content-small {
    width: 35px;
}
#header-logo .logo-content-small {
    left: 50%;
    margin-left: -17px;
    display: none;
}
#close-sidebar {
    color: rgba(255,255,255,0.7);
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -14px;
    height: 28px;
    line-height: 28px;
    width: 28px;
    display: block;
    font-size: 17px;
    text-align: center;
    border-radius: 3px;
}
#close-sidebar .glyph-icon {
    display: block;
    height: 28px;
    line-height: 28px;
    width: 28px;
}
#close-sidebar:hover {
    color: rgba(255,255,255,0.85);
    background: rgba(255,255,255,0.1);
}

#header-nav-left {
    float: right;
}

#header-nav-left .user-account-btn {
    height: 28px;
    line-height: 28px;
    margin: 15px 0 0;
}

#header-nav-left .user-account-btn > a {
    display: block;
    height: 28px;
    line-height: 28px;
}

#header-nav-left .user-account-btn > a span {
    font-weight: bold;
    font-size: 14px;
    padding-right: 5px;
    display: block;
    float: left;
}

#header-nav-left .user-account-btn > a > .glyph-icon {
    float: left;
}

#header-nav-left a > img {
    border-radius: 50px;
    margin: 0 5px 0 0;
    display: block;
    float: left;
}

#header-nav-right .hdr-btn,
#header-nav-right > .dropdown > a,
#header-nav-left .header-btn {
    font-size: 20px;
    height: 28px;
    line-height: 28px;
    width: 28px;
    text-align: center;
    float: right;
    margin: 15px 15px 0;
}

#header-nav-right {
    padding-left: 10px;
}

#header-nav-right > .dropdown {
    float: left;
}

#header-nav-right .hdr-btn,
#header-nav-right > .dropdown > a {
    float: left;
    font-size: 19px;
    padding: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: 5px 0 0;
    position: relative;
}

#page-header .small-badge {
    position: absolute;
    width: 18px;
    height: 18px;
    opacity: 0.7;
    border: 0;
    right: 6px;
    top: 7px;
    left: auto;
}

#page-header .dropdown-menu .button-pane {
    padding: 5px 0 0;
}

/* Sidebar */

#page-sidebar {
    width: 300px;
    float: left;
    position: relative;
    margin-right: -100%;
    z-index: 120;
    position: fixed;
    top: 0;
    left: 0;
}
/* Sidebar menu */

#sidebar-menu {
    margin: 0;
    padding: 5px 20px;
    list-style: none;
}
#sidebar-menu li {
    position: relative;
    margin: 8px 0;
}
#sidebar-menu li.header {
    text-transform: uppercase;
    font-size: 12px;
    padding: 5px 0;
    font-weight: 700;
}
#sidebar-menu li a {
    display: block;
    position: relative;
    line-height: 32px;
    height: 32px;
}
#sidebar-menu > li > a {
    padding: 0;
    font-size: 14px;
    line-height: 32px;
    height: 32px;
    font-weight: normal;
}
#sidebar-menu > li > a .bs-badge,
#sidebar-menu > li > a .bs-label {
    position: absolute;
    top: 6px;
    right: 29px;
}
#sidebar-menu > li.no-menu > a .bs-badge,
#sidebar-menu > li.no-menu > a .bs-label {
    right: 10px;
}
#page-sidebar li a.sf-with-ul:after {
    line-height: 21px;
    position: absolute;
    top: 50%;
    right: 5px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    -webkit-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -o-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
}
#page-sidebar li.sfHover > a.sf-with-ul:after {
    -webkit-transition-duration: .5s;
    -moz-transition-duration: .5s;
    -o-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
#page-sidebar li ul li a:before,
#page-sidebar li a .glyph-icon {
    font-size: 16px;
    line-height: 31px;
    display: block;
    float: left;
    width: 32px;
    height: 32px;
    margin-right: 10px;
    margin-left: 0;
    border-radius: 3px;
    text-align: center;
    opacity: .30;
    -moz-opacity: .30;
    filter: alpha(opacity: 30);
}
#page-sidebar li ul li a:before {
    font-size: 8px;
    width: 18px;
    margin: 0 8px 0 15px;
    height: 32px;
    line-height: 32px;
}
#page-sidebar li a .glyph-icon {
    opacity: .60;
    -moz-opacity: .60;
    filter: alpha(opacity: 60);
    -webkit-animation-duration: .7s;
    -moz-animation-duration: .7s;
    -o-animation-duration: .7s;
    animation-duration: .7s;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
}
#page-sidebar li a:hover .glyph-icon {
    -webkit-animation-name: swing;
    -moz-animation-name: swing;
    -o-animation-name: swing;
    animation-name: swing;
}
/* Sidebar sidebar-submenu */

#sidebar-menu li .sidebar-submenu {
    display: none;
    width: 300px;
    margin: 15px 0 0 -20px;
    list-style: none;
    padding: 10px 0;
}
#sidebar-menu li .sidebar-submenu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
#sidebar-menu li .sidebar-submenu ul li {
    margin: 0;
}

#sidebar-menu li .sidebar-submenu ul li a {
    font-size: 13px;
    font-family: Arial, sans-serif;
    padding: 0 20px;
}

#sidebar-menu li .sidebar-submenu ul li a:hover,
#sidebar-menu li .sidebar-submenu ul li a.active {
    background: rgba(0,0,0,0.2);
}


/* Page content wrapper */

#page-content-wrapper {
    float: left;
    width: 100%;
    position: relative;
    z-index: 110;
    padding-top: 60px;
}
#page-content {
    margin-left: 300px;
    padding: 20px 30px;
}
/* Page title */

#page-title {
    padding: 20px 30px;
    position: relative;
    background: rgba(252, 252, 252, 0.89);
    margin: -20px -30px 30px;
}
#page-title h2 {
    font-size: 18px;
    padding: 0;
    font-weight: bold;
    margin: -5px 0 0;
    color: #414C59;
}
#page-title p {
    opacity: 0.6;
    font-size: 13px;
}

#mobile-navigation {
    display: none;
}

#page-sidebar.collapse.in {
    visibility: visible;
    float: none;
    width: 100%;
    position: relative;
    display: block;
}
#page-sidebar.collapse.in .scroll-sidebar {
    height: auto !important;
}
#page-sidebar.collapse.in #sidebar-menu li .sidebar-submenu {
    width: auto !important;
    margin: 15px 0 0 0;
}


.t {
    -webkit-transition: transform 0.5s ease;
    -o-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
}
.a {
    transform: translateX(-300px);
    -ms-transform: translateX(-300px);
    -o-transform: translateX(-300px);
    -webkit-transform: translateX(-300px);
}