.tooltip {
    font-size: 12px;
    line-height: 1.4;
    position: absolute;
    z-index: 1030;
    display: block;
    visibility: visible;
    opacity: 0;
    filter: alpha(opacity=0);
}
.tooltip.in {
    opacity: .8;
    filter: alpha(opacity=80);
}
.tooltip.top {
    margin-top: -3px;
    padding: 5px 0;
}
.tooltip.right {
    margin-left: 8px;
    padding: 0 5px;
}
.tooltip.bottom {
    margin-top: 3px;
    padding: 5px 0;
}
.tooltip.left {
    margin-left: -8px;
    padding: 0 5px;
}
.tooltip-inner {
    max-width: 200px;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: rgba(0, 0, 0, .9);
}
.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
}
.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: rgba(0, 0, 0, .9);
}
.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: rgba(0, 0, 0, .9);
}
.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: rgba(0, 0, 0, .9);
}
.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: rgba(0, 0, 0, .9);
}
