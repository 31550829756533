/* Chosen Select */

.chosen-container {
    position: relative;
    display: inline-block;
    zoom: 1;
    width: 100% !important;
    vertical-align: middle;
    border-width: 1px;
    border-style: solid;
    *display: inline;
}
.chosen-container .chosen-drop {
    position: absolute;
    z-index: 1010;
    top: 38px;
    left: -9999px;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-top: 0;
    background: #fff;
}
.chosen-container.chosen-with-drop .chosen-drop {
    right: -1px;
    left: -1px;
    width: auto;
    padding: 10px;
}
.chosen-container-single .chosen-single {
    line-height: 38px;
    position: relative;
    display: block;
    overflow: hidden;
    height: 38px;
    margin: 0;
    padding: 0 10px !important;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
}
.chosen-container-single .chosen-single span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.chosen-container-single .chosen-single-with-deselect span {
    margin-right: 38px;
}
.chosen-container-single .chosen-single abbr {
    font-size: 9px;
    font-weight: bold;
    line-height: 16px;
    position: absolute;
    top: 9px;
    right: 26px;
    display: block;
    width: 16px;
    height: 16px;
    text-align: center;
}
.chosen-container-single .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0 0 10px;
    white-space: nowrap;
}
.chosen-container-single .chosen-search i,
.chosen-container-single .chosen-single div {
    line-height: 38px;
    position: absolute;
    z-index: 4;
    top: 50%;
    right: 0;
    display: block;
    width: 38px;
    height: 38px;
    margin-top: -19px;
    text-align: center;
    border-left: 1px solid transparent;
}
.chosen-container-active.chosen-with-drop .chosen-single div {
    border-bottom: 1px solid transparent;
    border-bottom-right-radius: 0;
}
.chosen-container-single .chosen-search i {
    margin-top: -19px;
    padding: 0 !important;
}
.chosen-container-single .chosen-search input {
    width: 100%;
    padding: 0 38px 0 5px;
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px;
}
.chosen-container .chosen-results {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 240px;
    -webkit-overflow-scrolling: touch;
}
.chosen-container .chosen-results li {
    line-height: 20px;
    margin: 5px 0;
    padding: 3px 10px;
    list-style: none;
}
.chosen-container .chosen-results li.group-result {
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}
.chosen-container .chosen-results li.active-result {
    display: list-item;
    cursor: pointer;
}
.chosen-container .chosen-results li.disabled-result {
    display: list-item;
    cursor: default;
    color: #ccc;
}
.chosen-container .chosen-results li.no-results {
    display: list-item;
    margin: 0;
    text-align: center;
}
.chosen-container .chosen-results li.no-results span {
    font-weight: bold;
}
.chosen-container .chosen-results li.group-option {
    padding-left: 15px;
}
.chosen-container .chosen-results li em {
    font-style: normal;
    text-decoration: underline;
}
/* Chosen multi */

.chosen-container-multi {
    height: auto;
    min-height: 38px;
    padding: 0;
}
.chosen-container-multi .chosen-choices {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: auto !important;
    height: 1%;
    margin: 0;
    padding: 0;
    cursor: text;
}
.chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none;
}
.chosen-container-multi .chosen-choices li.search-field {
    margin: 0;
    padding: 0 10px;
    white-space: nowrap;
}
.chosen-container-multi .chosen-choices li.search-field input {
    width: 100%;
    height: 38px;
    padding: 0;
    border: 0 !important;
    outline: 0;
    background: transparent !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.chosen-container-multi .chosen-choices li.search-field .default {
    color: #999;
}
.chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    margin: 6px 0 6px 6px;
    padding: 3px 25px 3px 5px;
    cursor: default;
    border-width: 1px;
    border-style: solid;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    line-height: 12px;
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    width: 12px;
    height: 12px;
    cursor: pointer;
    text-align: center;
    opacity: .5;
}
.chosen-container-multi .chosen-results li.result-selected {
    opacity: .6;
    filter: alpha(opacity=60);
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
    opacity: 1;
}
