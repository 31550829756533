    /* Progress Bar */
    
    .progress,
    .progress-bar,
    .progressbar,
    .progress-label,
    .progressbar-value,
    .progress-overlay {
        font-weight: bold;
        line-height: 20px;
        height: 20px;
        border-radius: 4px;
    }
    .progress,
    .progressbar {
        position: relative;
        text-align: center;
        background: rgba(0, 0, 0, .05);
        box-shadow: inset 1px 1px 3px rgba(0, 0, 0, .2);
    }
    .progress-bar,
    .progressbar-value,
    .progress-overlay,
    .progress-label {
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        overflow: hidden;
    }
    .progress .progress-bar {
        position: relative !important;
        border-radius: 0;
    }
    .progressbar-value.ui-state-default,
    .progressbar-value.ui-state-default .progress-label {
        line-height: 18px;
        height: 18px;
    }
    .progress-label {
        z-index: 6;
        width: 100%;
    }
    .progress-overlay {
        z-index: 5;
        width: 100%;
        opacity: .15;
        background: url('../../images/animated-overlay.gif');
        filter: alpha(opacity=15);
    }
    .progressbar-small.progressbar,
    .progressbar-small .progress-label,
    .progressbar-small .progressbar-value,
    .progressbar-small .progress-overlay {
        height: 10px;
    }
    .progressbar-small .progressbar-value.ui-state-default {
        height: 8px;
    }
    .progressbar-smaller.progressbar,
    .progressbar-smaller .progress-label,
    .progressbar-smaller .progressbar-value,
    .progressbar-smaller .progress-overlay {
        height: 4px;
    }
    .progressbar-smaller .progressbar-value.ui-state-default {
        height: 2px;
    }
    .bg-black .progress-overlay,
    .bg-black .progress-overlay {
        opacity: 1;
        filter: alpha(opacity=100);
    }
    /* Bootstrap progressbar */
    
    @-webkit-keyframes progress-bar-stripes {
        from {
            background-position: 40px 0;
        }
        to {
            background-position: 0 0;
        }
    }
    @keyframes progress-bar-stripes {
        from {
            background-position: 40px 0;
        }
        to {
            background-position: 0 0;
        }
    }
    .progress {
        overflow: hidden;
        height: 20px;
        margin-bottom: 20px;
        border-radius: 4px;
        background-color: #f5f5f5;
        -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
    }
    .progress-bar {
        font-size: 12px;
        line-height: 20px;
        float: left;
        width: 0;
        height: 100%;
        -webkit-transition: width .6s ease;
        transition: width .6s ease;
        text-align: center;
        color: #fff;
        -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
    }
    .progress-striped .progress-bar {
        background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
        background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
        background-size: 40px 40px;
    }
    .progress.active .progress-bar {
        -webkit-animation: progress-bar-stripes 2s linear infinite;
        animation: progress-bar-stripes 2s linear infinite;
    }
