  /* Dropdown */
  
  .dropup,
  .dropdown {
      position: relative;
      display: inline-block;
  }
  .ui-dialog,
  .ui-datepicker,
  .dropdown-menu,
  .popover,
  .ui-menu,
  .minicolors-panel {
      position: absolute;
      z-index: 1050 !important;
      top: 105%;
      left: 0;
      display: none;
      float: left;
      min-width: 150px;
      margin: 5px 0 0;
      padding: 5px;
      list-style: none;
      text-align: left;
      border-width: 1px;
      border-style: solid;
      background: #fff;
  }
  .ui-datepicker {
      padding: 0;
  }
  .popover,
  .dropdown-menu,
  .ui-dialog {
      box-shadow: 0 1px 7px 2px rgba(135, 158, 171, 0.2);
  }
  .dropdown-menu {
      font-size: 13px;
      line-height: 1.6em;
      min-width: 100%;
      padding: 5px 0;
      text-transform: none;
      border: 0;
  }
  .dropdown-menu {
      min-width: 150px;
  }
  .dropdown-menu,
  .popover,
  .ui-menu,
  .minicolors-panel {
      top: 100%;
  }
  .dropdown-menu .divider {
      margin: 5px 1px;
  }
  .dropdown-menu.float-right {
      right: 0;
      left: auto;
  }
  .dropdown-menu .header {
      font-size: 11px;
      font-weight: bold;
      line-height: 1.4em;
      margin: 0 0 5px;
      padding: 5px 5px 10px;
      text-transform: uppercase;
      color: #888;
      border-bottom: #dfe8f1 solid 1px;
  }
  .dropdown-menu .dropdown-header {
      font-size: 11px;
      font-weight: 300;
      padding: 5px 15px;
      text-transform: uppercase;
      color: #000;
  }
  .dropdown-menu li {
      position: relative;
  }
  .dropdown-menu li > a,
  .ui-menu li > a {
      font-weight: normal;
      line-height: 20px;
      position: relative;
      display: block;
      clear: both;
      margin: 0;
      padding: 3px 20px 3px 15px;
      padding: 5px 15px;
      cursor: pointer;
      white-space: nowrap;
  }
  .dropdown > .dropdown-menu:before,
  .minicolors-position-bottom .minicolors-panel:before {
      position: absolute;
      top: -7px;
      left: 7px;
      display: inline-block;
      content: '';
      border-right: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-bottom-color: transparent;
      border-left: 7px solid transparent;
  }
  .dropdown > .dropdown-menu:after,
  .minicolors-position-bottom .minicolors-panel:after {
      position: absolute;
      top: -6px;
      left: 8px;
      display: inline-block;
      content: '';
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-left: 6px solid transparent;
  }
  .dropdown > .dropdown-menu.float-right:before,
  .dropup > .dropdown-menu.float-right:before,
  .minicolors-position-right .minicolors-panel:before {
      right: 7px;
      left: auto;
  }
  .dropdown > .dropdown-menu.float-right:after,
  .dropup > .dropdown-menu.float-right:after,
  .minicolors-position-right .minicolors-panel:after {
      right: 8px;
      left: auto;
  }
  .minicolors-inline .minicolors-panel:after,
  .minicolors-inline .minicolors-panel:before {
      display: none;
  }
  .dropdown-dividers {
      padding: 0;
  }
  .dropdown-dividers li {
      padding: 3px;
      border-bottom: #ccc solid 1px;
  }
  .dropdown-dividers li:last-child {
      border-bottom: 0;
  }
  .dropdown-dividers li > a {
      padding: 2px 10px;
  }
  .push-left {
      left: 100% !important;
      margin-left: -30px;
  }
  .open {
      *z-index: 1000;
  }
  .open > .dropdown-menu {
      display: block;
  }
  .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
      margin-bottom: 8px;
  }
  .dropup > .dropdown-menu:before,
  .minicolors-position-top .minicolors-panel:before {
      position: absolute;
      bottom: -7px;
      left: 7px;
      display: inline-block;
      content: '';
      border-top: 7px solid #ccc;
      border-top-color: rgba(0, 0, 0, .2);
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
  }
  .dropup > .dropdown-menu:after,
  .minicolors-position-top .minicolors-panel:after {
      position: absolute;
      bottom: -6px;
      left: 8px;
      display: inline-block;
      content: '';
      border-top: 6px solid #fff;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
  }
  .minicolors-position-top.minicolors-position-right .minicolors-panel:before {
      right: 6px;
      left: auto;
  }
  .minicolors-position-top.minicolors-position-right .minicolors-panel:after {
      right: 7px;
      left: auto;
  }
  .dropdown-submenu {
      position: relative;
      z-index: 40;
  }
  .dropdown-submenu > .dropdown-menu {
      top: 50%;
      left: 90%;
      margin-top: -6px;
      margin-left: -1px;
      border-radius: 0 4px 4px 4px;
  }
  .dropdown-submenu:hover > .dropdown-menu {
      display: block;
  }
  .dropup .dropdown-submenu > .dropdown-menu {
      top: auto;
      bottom: 0;
      margin-top: 0;
      margin-bottom: -2px;
      border-radius: 4px 4px 4px 0;
  }
  .dropdown-submenu > a:after {
      position: relative;
      display: block;
      float: right;
      width: 0;
      height: 0;
      margin-top: 5px;
      margin-right: -5px;
      content: ' ';
      opacity: .40;
      border-width: 5px 0 5px 5px;
      border-style: solid;
      border-color: transparent;
      border-left-color: #ccc;
      -moz-opacity: .40;
      filter: alpha(opacity: 40);
  }
  .dropdown-submenu.float-left > .dropdown-menu {
      left: -100%;
  }
  .dropdown-submenu:hover > a:after {
      border-left-color: #fff;
  }
  .dropdown-submenu.float-left {
      float: none !important;
  }
  .dropdown-submenu.float-left > .dropdown-menu {
      left: -110%;
      margin-left: 10px;
      border-radius: 4px 0 4px 4px;
  }
  .dropdown-submenu.float-left > .dropdown-menu.dropdown-dividers {
      margin-left: 20px;
  }
  .ui-menu-item {
      padding: 3px 6px;
  }
