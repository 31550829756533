.form-wizard > ul {
    position: relative;
    display: table;
    width: 100%;
    margin: 0 0 20px;
    list-style: none;
}
.form-wizard > ul > li {
    display: table-cell;
    width: 1%;
    text-align: center;
}
.form-wizard > ul > li a,
.form-wizard > ul > li a:hover {
    position: relative;
    display: block;
    opacity: 1;
    color: #666;
    -moz-opacity: 1;
    filter: alpha(opacity: 100);
}
.form-wizard > ul > li a:before {
    position: absolute;
    z-index: 4;
    top: 20px;
    left: 0;
    width: 100%;
    height: 4px;
    content: '';
    background: #ccc;
}
.form-wizard > ul > li:first-child a:before {
    left: 50%;
}
.form-wizard > ul > li:last-child a:before {
    right: 50%;
    left: auto;
    width: 50%;
}
.form-wizard > ul > li a .wizard-step {
    font-size: 16px;
    line-height: 42px;
    position: relative;
    z-index: 5;
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto 5px;
    cursor: pointer;
    text-align: center;
    color: #fafafa;
    border-radius: 100px;
    background: #ccc;
}
.form-wizard > ul > li a .wizard-description {
    font-size: 13px;
    font-weight: bold;
    opacity: .60;
    -moz-opacity: .60;
    filter: alpha(opacity: 60);
}
.form-wizard > ul > li a .wizard-description small {
    font-size: 12px;
    font-weight: normal;
    display: block;
    padding: 3px 0 0;
    opacity: .70;
    -moz-opacity: .70;
    filter: alpha(opacity: 70);
}
.form-wizard > ul > li a .wizard-description:hover,
.form-wizard > ul > li.active a .wizard-description {
    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity: 100);
}
.form-wizard > ul > li a.disabled .wizard-step {
    background: #ccc;
}
.msgBox {
    display: none;
}
.loader {
    display: none;
}
.actionBar {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px 0 0;
    border-top: #ddd solid 1px;
}
.actionBar a.btn {
    float: right;
    margin-left: 10px;
}
