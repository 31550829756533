    /* Paddings */
    
    .pad5A {
        padding: 5px !important;
    }
    .pad5T {
        padding-top: 5px !important;
    }
    .pad5R {
        padding-right: 5px !important;
    }
    .pad5B {
        padding-bottom: 5px !important;
    }
    .pad5L {
        padding-left: 5px !important;
    }
    .pad10A {
        padding: 10px !important;
    }
    .pad10T {
        padding-top: 10px !important;
    }
    .pad10R {
        padding-right: 10px !important;
    }
    .pad10B {
        padding-bottom: 10px !important;
    }
    .pad10L {
        padding-left: 10px !important;
    }
    .pad15A {
        padding: 15px !important;
    }
    .pad15T {
        padding-top: 15px !important;
    }
    .pad15R {
        padding-right: 15px !important;
    }
    .pad15B {
        padding-bottom: 15px !important;
    }
    .pad15L {
        padding-left: 15px !important;
    }
    .pad20A {
        padding: 20px !important;
    }
    .pad20T {
        padding-top: 20px !important;
    }
    .pad20R {
        padding-right: 20px !important;
    }
    .pad20B {
        padding-bottom: 20px !important;
    }
    .pad20L {
        padding-left: 20px !important;
    }
    .pad25A {
        padding: 25px !important;
    }
    .pad25T {
        padding-top: 25px !important;
    }
    .pad25R {
        padding-right: 25px !important;
    }
    .pad25B {
        padding-bottom: 25px !important;
    }
    .pad25L {
        padding-left: 25px !important;
    }
    .pad45A {
        padding: 45px !important;
    }
    .pad45T {
        padding-top: 45px !important;
    }
    .pad45R {
        padding-right: 45px !important;
    }
    .pad45B {
        padding-bottom: 45px !important;
    }
    .pad45L {
        padding-left: 45px !important;
    }
    /* Remove paddings */
    
    .pad0A {
        padding: 0 !important;
    }
    .pad0T {
        padding-top: 0 !important;
    }
    .pad0R {
        padding-right: 0 !important;
    }
    .pad0B {
        padding-bottom: 0 !important;
    }
    .pad0L {
        padding-left: 0 !important;
    }
    /* Margins */
    
    .mrg5A {
        margin: 5px !important;
    }
    .mrg5T {
        margin-top: 5px !important;
    }
    .mrg5R {
        margin-right: 5px !important;
    }
    .mrg5B {
        margin-bottom: 5px !important;
    }
    .mrg5L {
        margin-left: 5px !important;
    }
    .mrg10A {
        margin: 10px !important;
    }
    .mrg10T {
        margin-top: 10px !important;
    }
    .mrg10R {
        margin-right: 10px !important;
    }
    .mrg10B {
        margin-bottom: 10px !important;
    }
    .mrg10L {
        margin-left: 10px !important;
    }
    .mrg15A {
        margin: 15px !important;
    }
    .mrg15T {
        margin-top: 15px !important;
    }
    .mrg15R {
        margin-right: 15px !important;
    }
    .mrg15B {
        margin-bottom: 15px !important;
    }
    .mrg15L {
        margin-left: 15px !important;
    }
    .mrg20A {
        margin: 20px !important;
    }
    .mrg20T {
        margin-top: 20px !important;
    }
    .mrg20R {
        margin-right: 20px !important;
    }
    .mrg20B {
        margin-bottom: 20px !important;
    }
    .mrg20L {
        margin-left: 20px !important;
    }
    .mrg25A {
        margin: 25px !important;
    }
    .mrg25T {
        margin-top: 25px !important;
    }
    .mrg25R {
        margin-right: 25px !important;
    }
    .mrg25B {
        margin-bottom: 25px !important;
    }
    .mrg25L {
        margin-left: 25px !important;
    }
    .mrg45A {
        margin: 45px !important;
    }
    .mrg45T {
        margin-top: 45px !important;
    }
    .mrg45R {
        margin-right: 45px !important;
    }
    .mrg45B {
        margin-bottom: 45px !important;
    }
    .mrg45L {
        margin-left: 45px !important;
    }
    /* Remove margins */
    
    .mrg0A {
        margin: 0 !important;
    }
    .mrg0T {
        margin-top: 0 !important;
    }
    .mrg0R {
        margin-right: 0 !important;
    }
    .mrg0B {
        margin-bottom: 0 !important;
    }
    .mrg0L {
        margin-left: 0 !important;
    }
