
.bootstrap-touchspin .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 8px 10px;
    margin-left: -1px;
    height: auto;
    position: relative;
    overflow: hidden;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0;
    border-top-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    margin-top: -2px;
    border-radius: 0;
    border-bottom-right-radius: 4px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
    position: absolute;
    top: -8px;
    left: 11px;
    font-size: 9px;
    font-weight: normal;
}
