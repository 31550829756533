/* Todo box */

ul.todo-box {
    margin: 0;
    padding: 0;
    list-style: none;
}
.todo-box li {
    margin: 0 0 2px;
    padding: 10px;
    border-left-width: 3px;
    border-left-style: solid;
    background: #fcfcfc;
}
.todo-box li .sort-handle {
    font-size: 16px;
    display: block;
    float: left;
    width: 18px;
    height: 18px;
    margin-right: 10px;
    cursor: move;
    text-align: center;
    opacity: .75;
    -moz-opacity: .75;
    filter: alpha(opacity: 75);
}
.todo-box li .sort-handle:hover {
    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity: 100);
}
.todo-box li a.btn-xs {
    margin-left: 3px;
}
.todo-box li input {
    display: block;
    float: left;
    width: auto;
    margin: 5px 5px 0 0;
}
.todo-box li label {
    padding: 0 5px;
    font-weight: normal;
}
.todo-box li .btn.small {
    line-height: 16px;
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-left: 5px;
    opacity: .75;
    -moz-opacity: .75;
    filter: alpha(opacity: 75);
}
.todo-box li.todo-done {
    opacity: .65;
    -moz-opacity: .65;
    filter: alpha(opacity: 65);
}
.todo-box li.todo-done label {
    font-style: italic;
    text-decoration: line-through;
}
