	/* Morris charts */
	
	.morris-hover {
	    position: absolute;
	    z-index: 1000;
	}
	.morris-hover.morris-default-style {
	    border-radius: 10px;
	    padding: 6px;
	    color: #666;
	    background: rgba(255, 255, 255, 0.8);
	    border: solid 2px rgba(230, 230, 230, 0.8);
	    font-family: sans-serif;
	    font-size: 12px;
	    text-align: center;
	}
	.morris-hover.morris-default-style .morris-hover-row-label {
	    font-weight: bold;
	    margin: 0.25em 0;
	}
	.morris-hover.morris-default-style .morris-hover-point {
	    white-space: nowrap;
	    margin: 0.1em 0;
	}
